import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../image/bodylogo.png";
import cert from "../image/cert.png";
import { BsTelephone } from 'react-icons/bs';
import {AiOutlineMail} from 'react-icons/ai';
export default function App() {
  return (
    <MDBFooter
      style={{}}
      className="text-center text-lg-start text-muted footer_back"
    >
      <div className="container">
        {" "}
        <Grid container spacing={2}>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <a href="./">  <img src={logo} style={{  padding: "20px" }} className="footer_logo_top" /></a>
          
          </Grid>

          <Grid item md={12} lg={12} xs={12} sm={12}>
            <h1 className="contactus_footer" style={{ padding: "10px" }}>
              We are the most trusted and caring provider of online
              prescriptions, supplements, and services in the pursuit of making
              a healthier new you!
            </h1>
            <p className="contactus_footerz" style={{ padding: "10px" }}>
              BODYLOGIC HEALTH AND WELLNESS® specializes in weight loss, hormone
              replacement, sexual health, and general wellness. Our futuristic
              methods offer a new and updated approach to achieving optimum
              health and wellness, through our online digital health platform,
              also known as telemedicine.
            </p>
            
          </Grid>
          <Grid item md={3} lg={3} xs={12} sm={12}>
            <h1 className="contactus_footer" style={{ padding: "20px" }}>
              <a href="">
                {" "}
                <FacebookIcon style={{ color: "#fff", fontSize: "40px" }} />
              </a>
              &nbsp; &nbsp;
              <a href="">
                {" "}
                <TwitterIcon style={{ color: "#fff", fontSize: "40px" }} />
              </a>
              &nbsp; &nbsp;
              <a href="">
                {" "}
                <InstagramIcon style={{ color: "#fff", fontSize: "40px" }} />
              </a>
            </h1>
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={12}></Grid>
          <Grid item md={3} lg={3} xs={12} sm={12}>
         
          </Grid>
        </Grid>
        <br />
        <h1 className="contactus_footerv" style={{ padding: "10px" }}>
          EXPLORE
        </h1>
      </div>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: "#Fff", fontSize: "20px" }}
              >
              SILDENAFIL
              </h6>
              <p style={{ fontSize: "17px", fontWeight: "700" }}>
                <a href="./SILDENAFILTroche" className="head_of_products_text" style={{color:"#63fff2c4"}}>
                SILDENAFIL Troche
                </a>
              </p>
             
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: "#Fff", fontSize: "20px" }}
              >
               TADALAFIL
              </h6>
              <p style={{ fontSize: "17px", fontWeight: "700" }}>
                <a  href="./Tadaliafil20mgTroche" className="head_of_products_text" style={{color:"#63fff2c4"}}>
                 20 mg Tadaliafil Troche
                </a>
              </p>
            </MDBCol>

            <MDBCol md="6" lg="6" xl="6"  className="mx-auto mb-4">
              <h6
                className="text-uppercase fw-bold mb-4"
                style={{ color: "#Fff", fontSize: "20px" }}
              >
               COMBINATIONS
              </h6>
              <p style={{ fontSize: "17px", fontWeight: "700" }}>
                <a href="./Sidenadil-Tadaldil" style={{color:"#63fff2c4"}} className="head_of_products_text">
                 Sildenafil 100 milligram + Tadalafil 40 milligram Troche
                </a>
              </p>

              <p style={{ fontSize: "17px", fontWeight: "700" }}>
                <a href="./Sidenadil-Papaverine" style={{color:"#63fff2c4"}} className="head_of_products_text">
                Sildenafil 110mg + Papaverine 20 mg Troche
                </a>
              </p>

              <p style={{ fontSize: "17px", fontWeight: "700" }}>
                <a href="./PEMaxTadalafil-Apomorphine" style={{color:"#63fff2c4"}} className="head_of_products_text">
                PE Max Tadalafil 75mg + Apomorphine 10 mg Trochee
                </a>
              </p>

              <p style={{ fontSize: "17px", fontWeight: "700" }}>
                <a href="./PT141-Tadalafil-Oxytocin" style={{color:"#63fff2c4"}} className="head_of_products_text">
                PT-141 2 mg + Tadalafil 20 mg + Oxytocin 100 iu
                </a>
              </p>
            </MDBCol>

    
          </MDBRow>
        </MDBContainer>
      </section>

      <Divider variant="middle" />
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">

            
            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-2" style={{position:"relative" , bottom:"50px"}}>
              <a href="./">  <img src={logo} style={{  padding: "20px" }} className="footer_logo_topu" /></a>
          
<hr style={{color:"#fff"}}></hr>
<p style={{ color: "#Fff", fontSize: "16px" }}>
Call us (Mon - Fri) <br/>
Message and data rates may apply
              </p>
              <p style={{ color: "#Fff", fontSize: "16px" }}>
<BsTelephone/> +1-800-480-4405
              </p>  
              <p style={{ color: "#Fff", fontSize: "16px" }}>
<AiOutlineMail/> support@bodylogic.com
              </p> 
              <hr style={{color:"#fff"}}></hr>
              <h1 className="contactus_footer" style={{ padding: "20px" }}>
              <a href="">
                {" "}
                <FacebookIcon style={{ color: "#fff", fontSize: "20px" }} />
              </a>
              &nbsp; &nbsp;
              <a href="">
                {" "}
                <TwitterIcon style={{ color: "#fff", fontSize: "20px" }} />
              </a>
              &nbsp; &nbsp;
              <a href="">
                {" "}
                <InstagramIcon style={{ color: "#fff", fontSize: "20px" }} />
              </a>
            </h1>
            </MDBCol>

            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4">
             
            </MDBCol>

            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4">
            <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/Term_and_Condition" className="text-reset">
                  Terms & Conditions
                </a>
              </p>

              <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/Privacy_Policy" className="text-reset">
                  Privacy Policy
                </a>
              </p>
              <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/Notice_of_Privacy_Practices" className="text-reset">
                  Notice of Privacy Practices
                </a>
              </p>
              <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/Refund_Policy" className="text-reset">
                  Cancellation/Refund Policy
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="3" xl="3" className="mx-auto mb-4">
              <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/ProductPage" className="text-reset">
                  Learn More
                </a>
              </p>
              <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/About_Us" className="text-reset">
                  About Us
                </a>
              </p>
              <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/Reviews" className="text-reset">
                  Reviews
                </a>
              </p>
              <p style={{ color: "#Fff", fontSize: "16px" }}>
                <a href="/Contact_Us " className="text-reset">
                  Contact Us
                </a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className=" footer_backs">
        {" "}
        <div className="container">
          <Grid container spacing={2}>
            <Grid item md={12} lg={12} xs={12} sm={12}>
              <p
                className="contactus_footerz"
                style={{ padding: "10px", textAlign: "center" }}
              >
                © Copyright 2023, bodylogichealthandwellnes.com. All Rights
                Reserved. <br />
                bodylogichealthandwellnes.com and the BODYLOGIC HEALTH AND
                WELLNESS logo are protected by U.S. and international copyright
                laws.
              </p>
            </Grid>
          </Grid>
        </div>
        <br />
      </div>
    </MDBFooter>
  );
}
