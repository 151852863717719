import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import images from "./image/logoo.png";
import "./Navbars.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Navbar = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };
  const [toggleMenu, setToggleMenu] = useState(false);
  const [navSize, setNavSize] = useState("10rem");
  const [navColor, setNavColor] = useState("transparent");

  const listenScrollEvent = () => {
    if (window.scrollY > 10) {
      setNavColor("#f2f2f2");
      setNavSize("5rem");
    } else {
      setNavColor("transparent");
      setNavSize("10rem");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  // ... your other code ...

  return (
    <nav className={`app__navbar ${navColor !== 'transparent' ? 'app__navbar-scroll' : ''}`} style={{ height: navSize }}>
      <div className="app__navbar-logo" style={{color:"#343f52"}}>
        <a href="./">
        <img src={images} style={{width:"250px"}}/>


        </a>
      </div>
      <ul className="app__navbar-links">
     
       
     <li className="p__opensans">
       <a
          href="#BaseScan"
         style={{ fontWeight: "800", textDecoration: "none" , color:"#343f52" }}
         className="pad"
       
       >
   BaseScan
       </a>
     </li>
     <li className="p__opensans">
       <a
         href="https://twitter.com/superbrianbase"
         style={{ fontWeight: "800", textDecoration: "none" ,color:"#343f52" }}
         className="pad"
       
       >
x
       </a>
     </li>

   
     <li className="p__opensans">
       <a
         href="https://t.me/superbrianbase"
         style={{ fontWeight: "800", textDecoration: "none" , color:"#343f52" }}
         className="pad"
         target="_blank"
       >
Telegram
       </a>
     </li>
     <li className="p__opensans">
       <a
         href="#Contact"
         style={{ fontWeight: "800", textDecoration: "none" , color:"#343f52" }}
         className="pad"
         
       >
Contact
       </a>
     </li>
     <li className="p__opensans">
       <a
        href="https://dexscreener.com/base/0xe8387583c30eb38325ed7843f84ec0eed4eb54ca"
         style={{ fontWeight: "800", textDecoration: "none" , color:"#343f52" }}
         className="pad"
         target="_blank"
       >

Chart
       </a>
     </li>
   </ul>
  
   <div className="app__navbar-smallscreen">
     <GiHamburgerMenu
   
       fontSize={33}
       onClick={() => setToggleMenu(true)}
       className="colorham"
     />
     {toggleMenu && (
       <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
        <AiOutlineClose
           fontSize={27}
           className="overlay__close"
           onClick={() => setToggleMenu(false)}
         />
         <br />
         <br />

         <ul className="app__navbar-smallscreen_links"> 
         
         
      
           <li className="li back_of">
           <a    onClick={() => setToggleMenu(false)} className="a" style={{fontSize:"20px"}}>
           Men
             </a>
             <Accordion    expanded={expandedAccordion === "combination"}
  onChange={handleAccordionChange("combination")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         <Typography style={{fontSize:"15px" , fontWeight:"bold"}}>  COMBINATION </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <a href="./Sidenadil-Tadaldil" onClick={() => setToggleMenu(false)}  style={{fontSize:"18px" , fontWeight:"bold"}} className="navdrops">
        Sildenafil 110 + Tadalafil 40 
          </a>
          <br/><br/>
          <a href="./Sidenadil-Papaverine" onClick={() => setToggleMenu(false)}  style={{fontSize:"18px" , fontWeight:"bold"}} className="navdrops">
          Sildenafil 110 + Papaverine 20
          </a>
          <br/><br/>
          <a href="./PEMaxTadalafil-Apomorphine" onClick={() => setToggleMenu(false)}  style={{fontSize:"16px" , fontWeight:"bold"}} className="navdrops">
          PE Max Tadalafil 75 + Apomorphine 10  
          </a>
          <br/><br/>
          <a href="./PT141-Tadalafil-Oxytocin" onClick={() => setToggleMenu(false)}  style={{fontSize:"16px" , fontWeight:"bold"}} className="navdrops">
          PT-141 2  + Tadalafil 20 + Oxytocin 100 iu
          </a>
        
        </AccordionDetails>
      </Accordion>





      <br />
      <Accordion  expanded={expandedAccordion === "tadalafil"}
  onChange={handleAccordionChange("tadalafil")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         <Typography style={{fontSize:"15px" , fontWeight:"bold"}}>   TADALAFIL </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <a href="./Tadaliafil20mgTroche" onClick={() => setToggleMenu(false)}  style={{fontSize:"18px" , fontWeight:"bold"}} className="navdrops">
        TADALAFIL 20 Troche
          </a>
         
        </AccordionDetails>
      </Accordion>
      
        <br />
   

        <Accordion expanded={expandedAccordion === "sildenafil"}
  onChange={handleAccordionChange("sildenafil")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{fontSize:"15px" , fontWeight:"bold"}}>SILDENAFIL</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <a href="./SILDENAFILTroche" onClick={() => setToggleMenu(false)}  style={{fontSize:"18px" , fontWeight:"bold"}} className="navdrops">
          SILDENAFIL 110 Troche
          </a>
        </AccordionDetails>
      </Accordion>
           </li>
          

           <li className="li back_of">
           <a    onClick={() => setToggleMenu(false)} className="a" style={{fontSize:"20px"}}>
       Female
             </a>
             <Accordion    expanded={expandedAccordion === "combinations"}
  onChange={handleAccordionChange("combinations")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         <Typography style={{fontSize:"15px" , fontWeight:"bold"}}> Products </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <a href="./DreamCream" onClick={() => setToggleMenu(false)}  style={{fontSize:"18px" , fontWeight:"bold"}} className="navdrops">
        Dream Cream
          </a>
          <br/><br/>
          <a href="./PT-141ComboforWomen" onClick={() => setToggleMenu(false)}  style={{fontSize:"18px" , fontWeight:"bold"}} className="navdrops">
          PT - 141 Combo for Women
          </a>
          <br/><br/>
         
          
        
        </AccordionDetails>
      </Accordion>
      
           </li>
          
           <li className="li back_of">
             <a
               href="./About_Us"
               onClick={() => setToggleMenu(false)}
               className=" a"
               style={{fontSize:"20px"}}
             >
   About Us
             </a>
           </li>
           <li className="li back_of">
             <a
            href="/AllWeightLossProgram"
               onClick={() => setToggleMenu(false)}
               className=" a"
               style={{fontSize:"20px"}}
             >
WEIGHT LOSS
             </a>
           </li>


           <li className="li back_of">
             <a
                href="/Vitamin"
               onClick={() => setToggleMenu(false)}
               className=" a"
               style={{fontSize:"20px"}}
             >
SUPPLEMENTS
             </a>
           </li>
           <li className="li back_of">
             <a
            href="tel:+1-800-480-4405"
               onClick={() => setToggleMenu(false)}
               className=" a"
               style={{fontSize:"17px"}}
             >
  +1-800-480-4405
             </a>
           </li>
            
           
       
          
          
           
         </ul>
       </div>
     )}
   </div>

    </nav>
  );
};

export default Navbar;
