import React from "react";
import { Grid } from "@mui/material";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
} from "mdb-react-ui-kit";
import Rev from "./image/manh.jpg";

import run from "./image/run.jpeg";
import { TbMinusVertical } from "react-icons/tb";
import { BsTelephone } from "react-icons/bs";
function CardsReviews() {
  return (
    <div>
      <div className="back_refund">
        <br />
        <br />
        <br />
        <br />
        <div className="container">
          <Grid container spacing={2}>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <p
                style={{
                  textAlign: "start",
                  fontWeight: "800",
                  color: "#fff",
                }}
                className="term_white_about"
              >
                Delivering  <br />Life-Changing Healthcare
                <br />
                <br />
              </p>

              <p
                style={{
                  textAlign: "start",
                  fontWeight: "300",
                  color: "#fff",
                }}
                className="term_whitex"
              >
                BODYLOGIC HEALTH AND WELLNESS is an online clinic that provides
                men with efficient, empowering and proactive healthcare as they
                age. By rejecting the belief that progressive physical and
                mental decline is a normal part of aging, BODYLOGIC HEALTH AND
                WELLNESS has blazed a new trail, avoiding debility and disease
                to enjoy youthful vitality.
                <br />
                <br />
                <br />
                <br />
              </p>
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              <img src={Rev} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="container">
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#000",
          }}
          className="term_white"
        >
          OUR PATIENTS
        </p>

        <p
          style={{
            textAlign: "start",
            fontWeight: "600",
            color: "#000",
          }}
          className="term_white_about"
        >
          Customer Stories
        </p>
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#000",
          }}
          className="term_white"
        >
          Read these Body Logic reviews to discover what patients love best
          about us:
        </p>
      </div>
      <br />
      <br />
      <div className="container">
        <Grid container spacing={2}>
          

          <Grid item md={6} lg={6} xs={12} sm={12}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <TbMinusVertical
                    style={{ color: "#3cb1a0", fontSize: "30px" }}
                  />{" "}
                  “My sexual confidence was at an all-time low due to my struggles with erectile dysfunction. 
I was constantly anxious about intimacy. 
After trying this special combination from BodyLogic, my life has taken a 180-degree turn. 
I feel like a new man, and my partner and I are closer than ever. 
BodyLogic has truly improved my life’s  sexual quality”
                </MDBCardText>
                <MDBCardTitle style={{ fontSize: "12px", fontWeight: "400" }}>
                  – John M
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </Grid>

          <Grid item md={6} lg={6} xs={12} sm={12}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <TbMinusVertical
                    style={{ color: "#3cb1a0", fontSize: "30px" }}
                  />{" "}
                  “Living with my husband's ED was challenging, and it affected our relationship deeply. 
We tried numerous medicines with little success. Then, we discovered BodyLogic ED formulas. It's like a miracle! My husband's newfound confidence has reignited our passion, and our connection is stronger than ever. Thank you, BodyLogic.”
                </MDBCardText>
                <MDBCardTitle style={{ fontSize: "12px", fontWeight: "400" }}>
                  –Sarah T
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </Grid>

          <Grid item md={6} lg={6} xs={12} sm={12}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <TbMinusVertical
                    style={{ color: "#3cb1a0", fontSize: "30px" }}
                  />{" "}
                  “My ED made me feel inadequate, and I avoided relationships altogether. But after taking this BODYLOGIC’s COMBINATION, I finally feel like I can fully participate in life and love. This combination has given me the gift of a fulfilling and satisfying sex life, and I couldn't be happier with the results.”
                </MDBCardText>
                <MDBCardTitle style={{ fontSize: "12px", fontWeight: "400" }}>
                  – David S
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </Grid>

          <Grid item md={6} lg={6} xs={12} sm={12}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <TbMinusVertical
                    style={{ color: "#3cb1a0", fontSize: "30px" }}
                  />{" "}
                  “DreamCream combination has been an absolute game-changer for my partner and me. Before discovering DreamCream , our intimate life was strained, and we were both frustrated. 
Now we feel like we're in our honeymoon phase all over again. This product has restored passion and intimacy in our relationship, and we can't recommend it enough!”
                </MDBCardText>
                <MDBCardTitle style={{ fontSize: "12px", fontWeight: "400" }}>
                  – Lisa W
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </Grid>

          <Grid item md={6} lg={6} xs={12} sm={12}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <TbMinusVertical
                    style={{ color: "#3cb1a0", fontSize: "30px" }}
                  />{" "}
                  “I've tried various solutions for my ED over the years, and none of them delivered the results I was hoping for. 
However,  PT141 by BodyLogic exceeded my expectations. 
My life before was filled with embarrassment and disappointment, but now I'm enjoying a fulfilling, confident, and satisfying sex life. This combination is the real deal, and I couldn't be more satisfied.”
                </MDBCardText>
                <MDBCardTitle style={{ fontSize: "12px", fontWeight: "400" }}>
                  – Charlie K
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </Grid>

          <Grid item md={6} lg={6} xs={12} sm={12}>
            <MDBCard>
              <MDBCardBody>
                <MDBCardText>
                  <TbMinusVertical
                    style={{ color: "#3cb1a0", fontSize: "30px" }}
                  />{" "}
                  “As a woman whose partner struggled with ED, I can't express how relieved we both are to have found BodyLogic.
Our relationship was on the brink, and intimacy felt like a distant memory. 
These combinations are amazing, since they have not only transformed my partner's confidence but also reignited our connection. 
We're both happier and closer than ever.”
                </MDBCardText>
                <MDBCardTitle style={{ fontSize: "12px", fontWeight: "400" }}>
                  – Veronique F
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          </Grid>


        </Grid>
      </div>
      <br />
      <br />

    </div>
  );
}

export default CardsReviews;
