import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Navbar from "../Component/Navbar";
import DesktopNavbar from "../Component/DesktopNavbar";
import HeroSection from "../Component/PT-Tadalafil/HeroPT-Tadalafil";
import Product from "../Component/PT-Tadalafil/ProductPT-Tadalafil";
import Footer from "../Component/HOME/Footer";
import Divider from "@mui/material/Divider";
import AllProducts from "../Products/PT-Tadalafil";
import QuantityPopup from "../Component/Popup/Quantity";
const PricingClasses = [
  { min: 1, max: 10, basePrice: 17 },
  { min: 11, max: 20, basePrice: 15 },
  { min: 21, max: 30, basePrice: 13 },
];

function Garden() {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="back" style={{ background: "" }}>
          <DesktopNavbar />
          <HeroSection />
          <Product />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div className="back" style={{ background: "" }}>
          <Navbar />      <br/><br/>
          <HeroSection />
          <Product />
          <Footer />
        </div>
      )}
         <QuantityPopup products={AllProducts} pricingClasses={PricingClasses} />
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
  );
}

export default Garden;
