import { useEffect, useState } from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { useCommonDispatch, useCommonState } from "../../store/Common"
import _ from 'lodash'

const QuantityPopup = (props) => {
    
    const store = useCommonState()
    const dispatch = useCommonDispatch()
    
    const [error, setError] = useState(false)
    const [product, setProduct] = useState()
    const [price, setPrice] = useState(0)
    const [show, setShow] = useState(false)

    const { products, pricingClasses } = props

    useEffect(() => {
        setShow( store.showQuantityPopup )
    }, [store.showQuantityPopup])

    useEffect(() => {
        handleQtyChanged( store.qtyPopupMinValue )
    }, [ store.qtyPopupMinValue ])

    const handleQtyChanged = (qty) => {

        if ( qty < store.qtyPopupMinValue || qty > store.qtyPopupMaxValue ) {
            setError( true )
            setProduct( null )
        }
        else {
            const product = _.find(products, { amount: qty })
            const pricingClass = _.find(pricingClasses, (cls) => {
                return qty >= cls.min && qty <= cls.max
            })
            
            if ( product && pricingClass ) {
                setError( false )
                setProduct( product )
                setPrice( parseFloat(qty * pricingClass.basePrice, 10) )
            }
            else {
                setError( true )
                setProduct( null )
            }
        }
    }

    const handleInputQty = (evt) => {
        const qty = parseInt( evt.target.value, 10 )
        handleQtyChanged( qty )
    }

    const handleRedirect = () => {
        if ( product ) {
            window.location.href = product?.link
        }
    }

    const handleClose = () => {
        dispatch({ type: 'SHOW_QUANTITY_POPUP', payload: false })
    }

    return (
        <Modal centered show={show}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="mb-3 d-block text-center">
                            From <strong>{store.qtyPopupMinValue} to {store.qtyPopupMaxValue}</strong>,  please type <br/> the amount you want to order
                        </Form.Label>
                        <Form.Control min={store.qtyPopupMinValue} max={store.qtyPopupMaxValue} className={`text-center ${error ? 'is-invalid' : ''}`} defaultValue={store.qtyPopupMinValue} onChange={handleInputQty} style={{ borderRadius: '15px', width: '50%', margin: '0 auto' }} type="number" />
                        {product && <div className="mt-3 text-center">Total Price: <strong>${price}</strong></div>}
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer className="centerall">
                    <Button  onClick={handleClose} className="btn-grado">Close</Button>
                    <Button onClick={handleRedirect} disabled={error ? true : false} className="btn-grado">Add to Cart</Button>
                </Modal.Footer>
      </Modal>
    )
}

QuantityPopup.defaultProps = {
    products: [],
    pricingClasses: {},
    min: 1, 
    max: 10,
}

export default QuantityPopup