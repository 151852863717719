
const AllProducts = [
    { amount: 1, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=8403016a-a37c-4378-b69c-7f3ece725fa7' },
    { amount: 2, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=245dcc91-ae63-4464-92a7-24dd60595384' },
    { amount: 3, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=c73829f9-100f-47b0-8bc1-4daef2442c7a' },
    { amount: 4, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=965b40f1-8b0f-4068-bd74-bc573e042adc' },
    { amount: 5, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=03ea753a-489e-4cd8-9fd7-96d7d248e608' },
    { amount: 6, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=337d0233-6004-4d37-9b70-857cbffa52f0' },
    { amount: 7, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=6501da72-7ca4-4ae6-b84e-8c73999582b5' },
    { amount: 8, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=58aa272a-2a40-4c50-b54f-6a9973224a6a' },
    { amount: 9, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=63459edb-2871-43ff-ab65-ae802e6698be' },
    { amount: 10, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=97dd7837-3ced-4b92-9e17-0ffbec44a2df' },
    { amount: 11, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=865298e0-0aa3-4c2b-a9e0-09c61304b217' },
    { amount: 12, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=2325fbe6-0f56-4a35-9193-7c1af454c33d' },
    { amount: 13, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=4d837748-26fc-4f9a-8073-7ef78b2387be' },
    { amount: 14, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=235515dd-73f1-40ef-ac51-569b037d7ee0' },
    { amount: 15, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=fb842a04-6217-48c6-b225-7afa16843411' },
    { amount: 16, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=a7d98381-64e4-4da1-b41d-509dab705728' },
    { amount: 17, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=d3959096-3516-4752-8d4c-04f1c24bc7be' },
    { amount: 18, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=b07861ec-54e5-4d06-b97e-84529c15e5cc' },
    { amount: 19, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=a4fb6088-5667-49bf-878c-bc60a713b0ef' },
    { amount: 20, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=bc18fbd5-d491-4dac-9cb5-e9d2861d506d' },
    { amount: 21, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=9e0aa489-930b-4451-9a48-78ad11481232' },
    { amount: 22, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=fdd64b6c-96d2-4e0c-81d8-32cd5ce676cb' },
    { amount: 23, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=1c58d3fb-6e79-4e7a-b4e0-2e28d971528c' },
    { amount: 24, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=94c441f5-2405-4e58-bff1-63a3484ec10a' },
    { amount: 25, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=31bc9e6d-4ada-423c-bf06-fc673657b72d' },
    { amount: 26, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=249cd23f-ed31-47b0-8573-149dab6a0cca' },
    { amount: 27, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=24be9b7c-8eef-4045-b7bc-0315d3afabec' },
    { amount: 28, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=aee2c73b-51cb-4627-8ca3-aa90062ddc03' },
    { amount: 29, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=61c2993f-cba0-4ad1-a556-8de9aecafac6' },
    { amount: 30, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=9febd5f7-9ce5-41cf-853e-836d20fa9073' },
]

export default AllProducts