import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/medicine.jpg";
import Divider from "@mui/material/Divider";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backk">
          <br />
          <br />
          <div className="container">
            <h1
              style={{
                textAlign: "start",
                color: "#000",
                fontWeight: "700",
                fontSize: "40px",
              }}
            >
              Sildenafil No Troche
            </h1>

            <br />
            <p
              style={{
                textAlign: "start",
                color: "#000",
                fontWeight: "400",
                fontSize: "15px",
              }}
            >
              Sildenafil is here to help men suffering from erectile dysfunction
              regain a normal healthy sex life. ED affects millions of men and
              most don’t know what to do or are to embarrassed to ask for help.
              Sildenafil is a powerful proven solution to assist men in having a
              normal response to sexual stimulation. Sildenafil needs to be
              taken 30-60 minutes before a man wishes to engage in sexual
              activity. Sildenafil works by increasing the blood flow to the
              penis and then constricting the arteries to help the body maintain
              an erection. Sildenafil is only for men and is a prescription
              medication. With the help of Sildenafil millions of men have
              overcome their sexual difficulties and are now enjoying a
              healthier sex life. Sildenafil not only restores a man’s sex
              abilities, but also his confidence.
            </p>

            <br />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card"
                >
                  <MDBRow className="g-0">
                    <Grid
                      item
                      md={12}
                      xs={12}
                      lg={12}
                      sm={12}
                      style={{ textAlign: "center" }}
                    >
                    <br />
                      <MDBCardImage
                        src={prod1}
                        alt="..."
                        className="products"
                      />
                      <br />
                    </Grid>
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#000",
                            fontWeight: "800",
                            fontSize: "34px",
                            textAlign: "center",
                          }}
                        >
                          Order in Bulk and Save <br /> Discounted Scale Pricing
                        </MDBCardTitle>
                        <br />
                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              QTY
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              Price
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}></Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />5 pills
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              $17.00 a Troche
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}>
                            {" "}
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />{" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",

                                  position: "relative",
                                  top: "-10px",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                className="term_white"
                              >
                                <button className="btn-grado">Order Now</button>
                              </MDBCardText>
                            </MDBCardText>
                          </Grid>
                        </Grid>
                        <Divider variant="middle" className="divider" />

                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              10 pills
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              $17.00 a Troche
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}>
                            {" "}
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />{" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",

                                  position: "relative",
                                  top: "-10px",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                className="term_white"
                              >
                                <button className="btn-grado">Order Now</button>
                              </MDBCardText>
                            </MDBCardText>
                            <Divider variant="middle" className="divider" />
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                15 pills
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $15.00 a Troche
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                  <button className="btn-grado">
                                    Order Now
                                  </button>
                                </MDBCardText>
                              </MDBCardText>
                              <Divider variant="middle" className="divider" />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                20 pills
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $15.00 a Troche
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                  <button className="btn-grado">
                                    Order Now
                                  </button>
                                </MDBCardText>
                              </MDBCardText>
                              <Divider variant="middle" className="divider" />
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                25 pills
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $13.00 a Troche
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                  <button className="btn-grado">
                                    Order Now
                                  </button>
                                </MDBCardText>
                              </MDBCardText>
                              <Divider variant="middle" className="divider" />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                30 pills
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $13.00 a Troche
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                  <button className="btn-grado">
                                    Order Now
                                  </button>
                                </MDBCardText>
                              </MDBCardText>
                            </Grid>
                          </Grid>
                        </Grid>

                        <MDBCardText>
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: "300",
                              color: "#000",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>

              <br />
              <br />
              <br />
            </Grid>
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Products;
