import React from "react";

import { Grid } from "@mui/material";

const UnderHero = () => {
  return (
    <div className="">
      <div className=" under_backs">
        <br />

        <div className="container under_backk">
          <br />
          <br />
          <h1
            style={{
              textAlign: "start",
              color: "#000",
              fontWeight: "700",
              fontSize: "25px",
            }}
          >
          Why Choose BodyLogic for
Your Erectile Dysfunction Needs?
          </h1>

          <br />
          <div className="container">
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
             At BodyLogic, we understand the impact that erectile dysfunction can have on your quality of life. Our mission is to empower you to take charge of your sexual health and rediscover the pleasure and confidence you deserve. When you choose BodyLogic, you're choosing a trusted partner with a track record of excellence in men's health. Our commitment to providing top-tier telemedicine services and the highest quality prescription medication sets us apart as a leader in the industry.
              <br />
            </p>

            <br />

            <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#000",
              }}
              className="term_white_about"
            >
              About us
              <br />
              <br />
            </p>

            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
            BODYLOGIC HEALTH AND WELLNESS has been a weight loss, anti-aging, and wellness
              provider since 2008. We offer virtual telecommunication options
              such as telemedicine or telehealth.
              <br /> <br />
              BODYLOGIC HEALTH AND WELLNESS® offers a new and futuristic approach to
              achieving optimal health and wellness. We set the standard for
              patients looking to receive quality medical care without
              boundaries.
              <br />
            </p>
          </div>



          <br />
        </div>
        <div className=" back_refund">
        <div className="container">
        <br/>
        <br/> <br />
          <p
            style={{
              textAlign: "center",
              fontWeight: "800",
              color: "#000",
            }}
            className="term_white_about"
          >
           Why Tele-Medicine?
            <br />
            <br />
          </p>

          <p
            style={{
              textAlign: "start",
              fontWeight: "300",
              color: "#000",
            }}
            className="term_white"
          >
           Say goodbye to long waiting rooms and awkward in-person visits. With BodyLogic's telemedicine platform, you can consult with our certified doctors from the comfort and privacy of your own home. Our user-friendly interface ensures a seamless experience, allowing you to connect with our medical experts conveniently and discreetly.
          </p>
          <br />
          <br />
        </div>

        <br />
      </div>
      <br />
          <br />
      </div>

    
          <div className=" under_backk">
          <br />
         
          <div className="">
       
          <div className="container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#000",
              }}
              className="term_white_about"
            >
             Expert Certified Doctors
              <br />
              <br />
            </p>

            <p
              style={{
                textAlign: "center",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
            Your health is our priority, which is why we have a team of experienced and board-certified doctors specializing in men's health and erectile dysfunction. When you choose BodyLogic, you're working with professionals who understand the unique challenges you may face and can tailor treatment plans to meet your individual needs.
              <br />
            </p> <br /></div>
     





            <br />   <br />

<div  className="back_of_grey"><br />
<div className="container">   <br /> <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#000",
              }}
              className="term_white_about"
            >
          Fast Prescription Delivery
              <br />
              <br />
            </p>

            <p
              style={{
                textAlign: "center",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
         We recognize that time is of the essence when it comes to your well-being. At BodyLogic, we ensure that once your consultation is complete, you'll receive your prescribed medication promptly and efficiently. Our streamlined process allows you to start your treatment journey without unnecessary delays.

              <br />
            </p></div>
          


            
            <br />   <br /></div>

            <br />


<div className="container"> <br /> <br /> <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#000",
              }}
              className="term_white_about"
            >
         Discreet and Private
              <br />
              <br />
            </p>

            <p
              style={{
                textAlign: "center",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
         Your privacy matters to us. We understand the sensitivity of the subject matter and take great care to ensure that your order is delivered discreetly and securely to your doorstep. With plain packaging and confidential shipping, you can have peace of mind knowing your personal information is protected at all times.

              <br />
            </p> <br />  
</div>

           






              
            <br />   <br />

<div className="back_refund"> <br />   <br /><div className="container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#000",
              }}
              className="term_white_about"
            >
    Top-Quality Medication
              <br />
              <br />
            </p>

            <p
              style={{
                textAlign: "center",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
        Your health and safety are non-negotiable. That's why BodyLogic exclusively sources medication from reputable pharmaceutical suppliers. Our commitment to providing you with the most effective and safe prescription options ensures you receive the best treatment for your specific needs.
              <br />
            </p>
</div> <br />   <br /></div>
            




               
            <br />   <br />


            <div className="container"> <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#000",
              }}
              className="term_white_about"
            >
  Comprehensive Care
              <br />
              <br />
            </p>

            <p
              style={{
                textAlign: "center",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
       BodyLogic goes beyond providing medication. We offer holistic support for your sexual health journey, including personalized treatment plans, lifestyle recommendations, and ongoing monitoring to ensure your progress. Our dedication to your long-term well-being sets us apart as a comprehensive and caring provider.
              <br />
            </p></div>

           
          </div>



          <br />
        </div>



    </div>
  );
};

export default UnderHero;
