import React from "react";

function HeroSection() {
  return (
    <div className=" background_malesexc">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className="honemf" style={{ textAlign: "start" }}>
          Treatments <br />
          for Sexual Dysfunction
          <span style={{ color: "#3cb1a0", fontSize: "22px" }}>
            <br />
            More stamina, more action. All from the comfort of home.
          </span>
        </h1>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white"
        >
          We offer exclusive, compounded ED programs as well as generic and
          alternatives.
        </p>
        <br />

       
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
