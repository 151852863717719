import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Test from "./BrimCollection";
import tel1 from "../image/tel1.jpeg";
import tel2 from "../image/tel2.jpeg";
import tel3 from "../image/tel3.jpeg";
import { Grid } from "@mui/material";

import { MDBBtn } from "mdb-react-ui-kit";
import ex1 from "./Image/Doctor-Edmonton.jpg";
import ex2 from "./Image/Doctor-Trotta.jpg";
function Telemedicine() {
  const theme = useTheme();
  return (
    <div className="">
  <div className="container">



  <h1
        style={{
          textAlign: "center",
          color: "#000",
   
        }}
        className="head_of_products"
      >
   Start Your Consultation Today
      </h1>
   
<br />
          <p
            style={{
              textAlign: "center",
        
              color: "#000",
            }}
            className="head_of_products_text"
          >
       Don't let erectile dysfunction hold you back any longer. Take control of your sexual health and reclaim your vitality. Click below to GET STARTED with your confidential consultation.
Our certified doctors will help you to begin your journey towards enhanced performance and satisfaction.   <br />   <br />
BodyLogic is here to support you every step of the way.
          </p>

<br />
<br />
<div className="centerall"><a href="ProductPage" style={{textDecoration:"none"}}> <button className="btn-grado" >GET STARTED</button></a></div>
<br />
<br />   
     {/* This is a comment inside JSX 

      <h1
        style={{
          textAlign: "start",
          color: "#000",
    
        }}
        className="head_of_products"
      >
        How Our Telemedicine Service Works.
      </h1>
      <br />
      <br />

      <MDBCard style={{ maxWidth: "100%", background: "#4343", color: "#000" }}>
        <MDBRow className="g-0">
          <MDBCol md="5">
          <MDBCardImage
  src={tel1}
  alt="..."
  fluid
  style={{
    height: "100%",
    objectFit: "cover", // This property prevents stretching
  }}
/>

          </MDBCol>
          <MDBCol md="7">
            <MDBCardBody>
              <MDBCardTitle className="tele_head" style={{textAlign:"center"}}>
                Instant online visits
              </MDBCardTitle>
            
              <MDBCardText className="tele_under"  style={{textAlign:"center"}}>
                Give us the 411 on your health history, prescriptions and your
                specific health needs
              </MDBCardText>
              <MDBCardText></MDBCardText>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <br />
      <br />

      <MDBCard style={{ maxWidth: "100%", background: "#4343", color: "#000" }}>
        <MDBRow className="g-0">
          <MDBCol md="5">
            <MDBCardImage
              src={tel2}
              alt="..."
              fluid
              style={{ height: "100%",
              objectFit: "cover", }}
            />
          </MDBCol>
          <MDBCol md="7">
            <MDBCardBody>
              <MDBCardTitle className="tele_head"  style={{textAlign:"center"}}>
                Two day shipping
              </MDBCardTitle>
           
              <MDBCardText className="tele_under"  style={{textAlign:"center"}}>
                Express 2-day shipping is standard on all
              </MDBCardText>
              <MDBCardText></MDBCardText>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
      <br />
      <br />
      <MDBCard style={{ maxWidth: "100%", background: "#4343", color: "#000" }}>
        <MDBRow className="g-0">
          <MDBCol md="5">
            <MDBCardImage
              src={tel3}
              alt="..."
              fluid
              style={{ height: "100%",
              objectFit: "cover", }}
            />
          </MDBCol>
          <MDBCol md="7">
            <MDBCardBody>
              <MDBCardTitle className="tele_head"  style={{textAlign:"center"}}>Continuous care</MDBCardTitle>
         
              <MDBCardText className="tele_under"  style={{textAlign:"center"}}>
                Reach out anytime to discuss updating your treatment or any
                other questions you might have
              </MDBCardText>
              <MDBCardText></MDBCardText>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>

      <br />
      <br />
      <h1
        style={{
          textAlign: "start",
          color: "#000",
       
        }}
        className="head_of_products_text"
      >
        Your healthcare provided confidently & discreetly by licensed physicians
      </h1>
      <br />
      <br />
      <div>

      </div>
      <br />


  </div>


      <div className=" under_backs">
        <div className=" under_backk">
          <br />
          <br />

          <br />

          <h1
            style={{
              textAlign: "center",
              color: "#000",
          
            }}
            className="head_of_products"
          >
            TESTIMONIALS
          </h1>

          <br />
          <p
            style={{
              textAlign: "center",
              fontWeight: "300",
              fontSize: "20px",
              color: "#000",
            }}
          >
            Patient Stories
          </p>

          <Grid container spacing={2}>
            <Grid item md={12} lg={12} xs={12} sm={12}>
              <Test />

            
            </Grid>
          </Grid>

          <br />
        </div>

        <br />
        <br />
      </div>

      <div className="container"><br /><br />
        <p
          style={{
            textAlign: "center",
            fontWeight: "800",
            color: "#000",
          }}
          className="term_white_exe"
        >
          Meet the Medical Experts
          <br /><br />
        </p>
        <br />
        <Grid container spacing={3}>
          <Grid item md={6} lg={6} xs={12} sm={6}>
            <MDBCard className="back_exec_card">
              <MDBCardImage src={ex1} position="top" alt="..." />
              <MDBCardBody>
                <MDBCardTitle> Doctor-Edmonton</MDBCardTitle>
                <hr />
                <MDBCardTitle>Founder & Chief Executive Officer</MDBCardTitle>
                <MDBCardText    className="head_of_products_text">
                  Recognized as a technology leader with an entrepreneurial
                  drive for crafting the requisite strategic vision to achieve
                  business goals, Andreas Dettlaff offers a unique blend of
                  executive acumen and IT solutions development credited with
                  strong technical and operational improvement at BODYLOGIC
                  HEALTH AND WELLNESS®.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={6}>
            <MDBCard className="back_exec_card">
              <MDBCardImage src={ex2} position="top" alt="..." />
              <MDBCardBody>
                <MDBCardTitle> Doctor-Trotta</MDBCardTitle>
                <hr />
                <MDBCardTitle>Chief Operating Officer</MDBCardTitle>
                <MDBCardText    className="head_of_products_text">
                  Alejandra Pepper serves as the Chief Operations Officer for
                  BODYLOGIC HEALTH AND WELLNESS®. She is in charge of overseeing
                  the overall success of the company by providing direction to
                  the people who help build and maintain BODYLOGIC HEALTH AND
                  WELLNESS®.
                </MDBCardText>
                <br />
              </MDBCardBody>
            </MDBCard>
          </Grid>
        </Grid>

        <br />*/}
        <br />
      </div>
      <br />
    </div>
  );
}

export default Telemedicine;
