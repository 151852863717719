import React from "react";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import I52 from "./image/52.png";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

function AboutErectileXFAQ() {
  return (
    <>
      <div className="back_refund">
        <div className=" under_backs">
          <br />
          <div className=" ">
            <div className="container">
              <Grid container>
                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  sm={12}
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <MDBCard
                    style={{
                      maxWidth: "100%",
                      background: "#4343",
                      color: "#fff",
                    }}
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="4">
                        <MDBCardImage
                          src={I52}
                          alt="..."
                          fluid
                          style={{ height: "100%", width: "70%" }}
                        />
                      </MDBCol>
                      <MDBCol md="8">
                        <MDBCardBody>
                          <MDBCardTitle className="tele_head">
                            About Sexual Dysfunction 
                          </MDBCardTitle>
                          <br />
                          <br />
                          <MDBCardText className="tele_under">
                          Sexual Dysfunction  is the inability to achieve
                            erections and sustain them during sexual
                            intercourse. This happens a lot and affects millions
                            of men for a variety of reasons. More than 50% of
                            men experience some form of ED during their
                            lifetime.
                          </MDBCardText>
                          <MDBCardText></MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>{" "}
                </Grid>
              </Grid>

           
            </div>
       
           
          </div>

       
        </div>
      </div>
    </>
  );
}

export default AboutErectileXFAQ;
