import React from "react";
import { Grid } from "@mui/material";
function HeroSection() {
  return (
    <div className="background_hero">
      <div className="container ">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item md={6} lg={6} xs={12} sm={12}></Grid>

          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            sm={12}
            className="container backOFhero"
            style={{ textAlign: "center" }}
          >
            <h1 className="honehh"   style={{ textAlign: "center" }}>Welcome </h1>
            <br />
            <p className="pone">
            Your Trusted Source for<br />
Logical Health & Wellness
              <br />
            </p>
            <Grid
              container
              spacing={2}
              style={{ position: "relative", top: "10px" }}
            >
              <Grid item md={6} lg={6} xs={12} sm={12}>
                <div class="containers">
                  <div class="wrapper">
                    <p className="ps" style={{ color: "#fff" }}>
                      New Patient
                    </p>
                    <a className="btn-gradpohome" href="./ProductPage">
                      Get Started
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} lg={6} xs={12} sm={12}>
                <div class="containers">
                  <div class="wrapper">
                    {" "}
                    <p className="ps" style={{ color: "#fff" }}>
                      Returning Patient
                    </p>
                    <a href="/book" className="btn-gradpohome"> Login Here</a>
                  </div>
                </div>
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <br />
        <div className="center"> </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
