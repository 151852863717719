import React from "react";
import { Grid } from "@mui/material";
import I52 from "./image/test.jpeg";
import level from "./image/level.png";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

function AboutErectileXFAQ() {
  return (
    <>
      <div className=" container back_refund">
        <div className=" under_backs">
          <br />
          <div className=" ">
            <div className="container">
              <Grid container>
                <Grid
                  item
                  md={12}
                  lg={12}
                  xs={12}
                  sm={12}
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <MDBCard
                    style={{
                      maxWidth: "100%",
                      background: "#4343",
                      color: "#fff",
                    }}
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="7">
                        <MDBCardBody>
                          <MDBCardTitle className="tele_head">
                            Low testosterone?
                          </MDBCardTitle>
                          <br />
                          <br />
                          <MDBCardText className="tele_under">
                            Testosterone is the primary sex hormone in males. It
                            is produced in the testicles and plays a crucial
                            role in the development of male reproductive
                            tissues, muscle tone, body hair, and bone mass.
                            However, when a male's gonads don't produce enough
                            testosterone, his T levels drop, developing a
                            condition known as male hypogonadism, which can lead
                            to erectile dysfunction, depression, low muscle
                            tone, and infertility.
                          </MDBCardText>
                          <MDBCardText></MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                      <MDBCol md="5">
                        <MDBCardImage
                          src={I52}
                          alt="..."
                          fluid
                          style={{ height: "100%", width: "100%" }}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>{" "}
                </Grid>
         
              </Grid>

              <br />

              <br />
            </div>
            <br />
          </div>
        </div>

        <Grid
        item
        md={12}
        lg={12}
        xs={12}
        sm={12}
        style={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <MDBCard
          style={{
            maxWidth: "100%",
            background: "#4343",
            color: "#fff",
          }}
        >
          <MDBRow className="g-0">
            <MDBCol md="12">
              <MDBCardBody>
                <MDBCardTitle className="tele_head">
                  Low testosterone?
                </MDBCardTitle>
                <br />
                <br />
               
              </MDBCardBody>
            </MDBCol>
            <MDBCol md="12">
              <MDBCardImage
                src={level}
                alt="..."
                fluid
                style={{ height: "100%", width: "100%" }}
              />
            </MDBCol>
          </MDBRow>
        </MDBCard>{" "}
      </Grid>
      <br/>
      </div>
    </>
  );
}

export default AboutErectileXFAQ;
