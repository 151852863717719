import React from "react";
import "./UnderCard.css";
import { Grid } from "@mui/material";
import "./Testosteronr.scss";

const UnderHero = () => {
  return (
    <div className="">
      <div className="happy_image_patient">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <br /><br /><br />
    <div className="container">
    <h1
  style={{
    textAlign: "center",
    color: "#000",

  }}
  className="head_of_products"
>
Your Journey to Improved<br /> Sexual Health Starts Now
</h1>
<br />

<br />
<br />
          <p
            style={{
              textAlign: "center",
         
              color: "#000",
            }}
            className="head_of_products_text"
          >
        Take the first step towards a more satisfying life by choosing BodyLogic as your trusted provider of erectile dysfunction prescription medication. Our telemedicine platform, staffed by certified doctors and backed by excellent customer service, ensures you receive personalized care in the most discreet and convenient manner. Enjoy fast prescription delivery and access to top-quality medication tailored to your specific needs. Regain your confidence and vitality today with BodyLogic.
          </p>

<br />
<br /><br />
      </div>  

    
    </div>
  );
};

export default UnderHero;
