import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Navbar from "../Component/Navbar";
import DesktopNavbar from "../Component/DesktopNavbar";
import Footer from "../Component/HOME/Footer";
import { useSearchParams } from "react-router-dom";

function Booking() {
    const theme = useTheme();
    const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="garden">
            {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

            {/* <Slider /> */}
            {!smallScreen && (
                <div className="back">
                    <DesktopNavbar />

                    {(searchParams.get('invite_booking_with_mss') && searchParams.get('product_ids')) && 
                        <h1
                            style={{
                                textAlign: "center",
                                color: "#000",

                            }}
                            className="head_of_products"
                        > <br /><br />
                            MEDICAL QUESTIONNAIRE<br /><br />
                        </h1>
                    }

                    <div id="iunUvVsdwR" className="dc-standalone-page gkim-digital-clinic"></div>
                    <br /><br />
                    <Footer />
                </div>
            )}
            {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
            {smallScreen && (
                <div className="back">
                    <Navbar />
                    <br /><br /> 
                    <br /><br />      
                    

                    {(searchParams.get('invite_booking_with_mss') && searchParams.get('product_ids')) &&   
                    <h1
                        style={{
                            textAlign: "center",
                            color: "#000",

                        }}
                        className="head_of_products"
                    > <br /><br />
                        MEDICAL QUESTIONNAIRE<br /><br />
                    </h1>}

                    <div id="iunUvVsdwR" className="dc-standalone-page gkim-digital-clinic"></div> <br /><br />
                    <Footer />
                </div>
            )}
            {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
        </div>
    );
}

export default Booking;
