import React from "react";
import pri from './privacy.jpg'
import { Grid } from "@mui/material";

const UnderHero = () => {
  return (
    <div className="">
      <div className=" under_backs">
        <br />

        <div className=" under_backk">
          <br />
          <br />

          <br />

          <h1
            style={{
              textAlign: "center",
              color: "#000",
              fontWeight: "800",
              fontSize: "50px",
            }}
          >
            Body Logic Privacy Policy
          </h1>

          <br />
          <br />
          <p
            style={{
              textAlign: "center",
              fontWeight: "300",
              fontSize: "16px",
              color: "#000",
            }}
          >
            Last modified: March 25th, 2022
          </p>
          <br />
          <div className="container">
           <img src={pri} style={{width:"100%"}}/>
          </div>
          <br />
          <div className="container">
            <p
              style={{
                textAlign: "start",
                fontWeight: "800",
                color: "#3CD3AD",
              }}
              className="term_white"
            >
              Privacy and Security
              <br />
            </p>
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
              BodyLogic Health and Wellness is dedicated to maintaining all
              patients/clients privacy. All information collected online is
              strictly and exclusively for use by BodyLogic to appropriately
              care and manage all individuals. BodyLogic will never sell, trade,
              or exchange any patient information and will always handle all
              personal information professionally and responsibly.  BodyLogic
              reserves the right to change and update all privacy and security
              policies. The challenge of all web/internet privacy and security
              codes are constantly changing. BodyLogic will always remain
              up-to-date with the use of fire walls and Secure Sockets Layer
              (SSL) technology to help insure your information is protected and
              private. <br />
              <br /> BodyLogic limits all staff and employees access to patient
              information through strict security and authorization codes. All
              staff and employees are oriented to security and privacy policies
              and are updated regularly.  Private password access to various
              areas of information, purchases, laboratory results, and all
              correspondents are strictly enforced and continuously monitored. 
              BodyLogic urges all patients/clients to keep all information and
              medical profiles up-to-date at all times to insure best results
              and up most safety.
              <br />
             
            </p>

            <br />

            <p
              style={{
                textAlign: "start",
                fontWeight: "800",
                color: "#3CD3AD",
              }}
              className="term_white"
            >
              COOKIES
              <br />
            </p>
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
              BodyLogic uses cookies to help our website recognize you when you
              enter. This will enable BodyLogic to know you have been in our
              site and help expedite a better shopping experience. A cookie is
              nothing more than a small file that your computer stores on its
              hard drive. Once our cookie is stored each time you log onto our
              site, the site simply recognizes you and will make your browsing a
              little faster. These cookies are harmless and can only be
              recognized by the source that created it. If you want the cookies
              removed from your computer just refer to your systems help
              section. Search the phrase "remove cookies" it should guide you on
              how to remove the cookies from your system.
              <br />
              <br />
            </p>

            <br />

            <p
              style={{
                textAlign: "start",
                fontWeight: "800",
                color: "#3CD3AD",
              }}
              className="term_white"
            >
              CREDIT CARD INFORMATION
              <br />
            </p>
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
              BodyLogic treats your credit card information with the highest
              level of security. We use a Secure Socket Layer (SSL)  Technology.
              This is the most advanced form of security for credit card
              transactions over the Internet. The way this technology works is
              when you enter any sensitive information when it leaves your
              computer it gets mixed up. The information stays mixed up until it
              reaches our secure server. Once in our server it is put back in
              order by the same source that mixed it up and then used for its
              intended purpose. The data cannot be read while being transferred
              and the only source that can unscramble it is the source that
              scrambled it to start with, and that is located on our server.
              After your order is shipped and we have received payment from our
              merchant BodyLogic destroys all credit card information.
              <br />
              <br />
            </p>
            <br />
            <p
              style={{
                textAlign: "start",
                fontWeight: "800",
                color: "#3CD3AD",
              }}
              className="term_white"
            >
              E-MAIL ADDRESS
              <br />
            </p>
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
              BodyLogic understands that just because you purchase a product
              from us does not mean you want your e-mail box filled with spam or
              junk mail. BodyLogic will never sell your e-mail address to any
              company for any reason. The only mail you will receive is from our
              site. The only way you will receive e-mail from our site is in
              reference to your order or questions.
              <br />
              <br />
            </p>

            <br />
          </div>

          <br />
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};

export default UnderHero;
