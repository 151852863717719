import React from "react";

function HeroSection() {
  return (
    <div className=" background_femalesex">

<div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />  <br />
        <br />
        <br />
        <br />
        <br /> 

        <br /> 
        <br />
        <h1 className="hone" style={{ color: "#fff" }}>
        Dream Cream
          <span style={{ color: "#3cb1a0", fontSize:"20px"}}>
            <br />
            Increase your sexual satisfaction, achieve better
orgasms and bring the excitement back to the bedroom.

          </span>
        </h1>
        <br />

        <br />

        <br />
        <p
          style={{
           
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br /> <br />
      </div>
   
    </div>
  );
}

export default HeroSection;
