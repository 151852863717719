import React, { useRef, useState } from "react";

import "react-bootstrap-submenu/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, ButtonToolbar } from "rsuite";
import "./Navbar.css";
import logo from "./image/logoo.png";
import "rsuite/dist/rsuite.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Button } from "rsuite";
function CollapsibleExample() {
  const navRef = useRef();

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className="main_desktop_navbar"
    >
      <>
        <a href="./">
          {" "}
          <img src={logo} className="navbar_logo" />
        </a>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav ref={navRef}>
            <ButtonToolbar>
            <Dropdown title="Sexual Dysfunction" trigger="hover">
  <Dropdown.Menu title={<a href="/Male">Male</a>}   trigger="contextMenu">
    <Dropdown.Menu title="SILDENAFIL" className="back_new" >
      <Dropdown.Item> <a href="./SILDENAFILTroche" className="back_new">
                        SILDENAFIL 110 Troche
                      </a></Dropdown.Item>
    </Dropdown.Menu>
    <Dropdown.Menu title="TADALAFIL" className="back_new">
      <Dropdown.Item>  <a
                        href="./Tadaliafil20mgTroche"
                        className="back_new"
                      >
                        TADALAFIL 20 Troche
                      </a></Dropdown.Item>
    </Dropdown.Menu>
    <Dropdown.Menu title="COMBINATION" className="back_new" >
    <Dropdown.Item>
                      {" "}
                      <a href="./Sidenadil-Tadaldil" className="back_new">
                        Sildenafil 110 + Tadalafil 40 
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <a href="./Sidenadil-Papaverine" className="back_new">
                        Sildenafil 110 + Papaverine 20 
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <a
                        href="./PEMaxTadalafil-Apomorphine"
                        className="back_new"
                      >
                        Tadalafil 75 + Apomorphine 10 
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <a href="./PT141-Tadalafil-Oxytocin" className="back_new">
                        PT-141 2 + Tadalafil 20 + Oxytocin 100 iu
                      </a>
                    </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown.Menu>
  <Dropdown.Menu  title={<a href="/Female">Female</a>} className="back_new" >
  <Dropdown.Item className="back_new">

<a href="./DreamCream" className="back_new">
Dream Cream
    </a>
 
</Dropdown.Item>
<Dropdown.Item className="back_new">  
 
  <a href="./PT-141ComboforWomen" className="back_new">
  PT-141 Combo for Women
    </a>
</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>

            </ButtonToolbar>
          
            
            
            
            
            
            <Nav.Link
              href="/AllWeightLossProgram"
              id="style-2"
              className="font_nav_text  text_head"
            >
              {" "}
              <Button> WEIGHT LOSS</Button>
            </Nav.Link>
          

            <Nav.Link
              href="/Vitamin"
              id="style-2"
              className="font_nav_text  text_head"
              style={{ color: "#fff" }}
            >
              {" "}
              <Button> SUPPLEMENTS</Button>
            </Nav.Link>
            
            
            
            
       
            

            <Nav.Link
              href="/About_Us"
              id="style-2"
              className="font_nav_text  text_head"
              style={{ color: "#fff" }}
            >
              {" "}
              <Button> About Us</Button>
            </Nav.Link>
            <Nav.Link
              id="style-2"
              className="font_nav_text  text_head"
              style={{ color: "#fff", fontSize: "15px" }}
            >
              {" "}
              {/* <Button>
                {" "}
                <FaShoppingCart />
              </Button> */}
              <div id="bw-cartteetts" className="gk-btn-cart-widget"></div>
            </Nav.Link>

            <Nav.Link
              href="tel:+1-800-480-4405"
              id="style-2"
              className="font_nav_text  text_head"
              style={{ color: "#3cb1a0" }}
            >
              {" "}
              <Button> +1-800-480-4405</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </>
    </Navbar>
  );
}

export default CollapsibleExample;
