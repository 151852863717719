import React, { useEffect } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./UnderCard.css";
import { Grid } from "@mui/material";
import "./Testosteronr.scss";
import gym from "../image/test.jpeg";
import evert from "../image/evert.jpeg";
const UnderHero = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = false;
    document.body.appendChild(script);
  }, []);
  return (
    <div className="container">
      <div className=" under_backs">
        <br />
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
            sm={12}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <iframe
              id="JotFormIFrame-231714501025644"
              title="BODYLOGIC ED Medical Assessment"
              onLoad={() => window.parent.scrollTo(0, 0)}
              allowtransparency="true"
              allowFullScreen="true"
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/231714501025644"
              frameBorder="0"
              style={{
                minWidth: "100%",
                maxWidth: "100%",
                height: "539px",
                border: "none",
              }}
              scrolling="no"
            ></iframe>
          </Grid>
        </Grid>

        <br />
      </div>
    </div>
  );
};

export default UnderHero;
