import React from "react";
import { Grid } from "@mui/material";
import prod1 from "./image/fruit.png";
import prod3 from "./image/nuts.png";
import prod2 from "./image/vege.png";
import prod4 from "./image/fish.png";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              {" "}
              <li class="cards_item" id="item_salad">
                <div class="card">
                  <div class="card_image">
                    <img
                      src={prod1}
                      alt="mixed vegetable salad in a mason jar. "
                    />
                  </div>
                  <div class="card_content">
                    <h6 class="card_title">
                      You lost the weight, now it's time to WAYT-tain
                    </h6>
                    <div class="card_text">
                      <p>
                        The WAYT-tain™ E-book has everything you need to know
                        about your weight loss maintenance and grants you access
                        to:
                      </p>
                      <p>
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                        <br />
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </Grid>

            <Grid item md={6} lg={6} xs={12} sm={12}>
              {" "}
              <li class="cards_item" id="item_salad">
                <div class="card">
                  <div class="card_image">
                    <img
                      src={prod3}
                      alt="mixed vegetable salad in a mason jar. "
                    />
                  </div>
                  <div class="card_content">
                    <h6 class="card_title">
                      You lost the weight, now it's time to WAYT-tain
                    </h6>
                    <div class="card_text">
                      <p>
                        The WAYT-tain™ E-book has everything you need to know
                        about your weight loss maintenance and grants you access
                        to:
                      </p>
                      <p>
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                        <br />
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} lg={6} xs={12} sm={12}>
              {" "}
              <li class="cards_item" id="item_salad">
                <div class="card">
                  <div class="card_image">
                    <img
                      src={prod2}
                      alt="mixed vegetable salad in a mason jar. "
                    />
                  </div>
                  <div class="card_content">
                    <h6 class="card_title">
                      You lost the weight, now it's time to WAYT-tain
                    </h6>
                    <div class="card_text">
                      <p>
                        The WAYT-tain™ E-book has everything you need to know
                        about your weight loss maintenance and grants you access
                        to:
                      </p>
                      <p>
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                        <br />
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </Grid>

            <Grid item md={6} lg={6} xs={12} sm={12}>
              {" "}
              <li class="cards_item" id="item_salad">
                <div class="card">
                  <div class="card_image">
                    <img
                      src={prod4}
                      alt="mixed vegetable salad in a mason jar. "
                    />
                  </div>
                  <div class="card_content">
                    <h6 class="card_title">
                      You lost the weight, now it's time to WAYT-tain
                    </h6>
                    <div class="card_text">
                      <p>
                        The WAYT-tain™ E-book has everything you need to know
                        about your weight loss maintenance and grants you access
                        to:
                      </p>
                      <p>
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                        <br />
                        Detailed breakdown of our Wayt-tain™ product.
                        <br />
                        Benefits and science of how it works.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Products;
