import React from "react";

function HeroSection() {
  return (
    <div className=" ">
      <div className="container">
       <br/>       <br/>       <br/>
        <h1 className="honeabs" style={{ textAlign: "center" }}>
          About <br />
          <span style={{ color: "#3cb1a0" }}>Body Logic</span>
        </h1> 
       
      </div>
    </div>
  );
}

export default HeroSection;
