import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/upda.jpeg";
import prod2 from "./image/prod2.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backk">
          <br />
          <br />
          <div className="container">
            <h1
              style={{
                textAlign: "start",
                color: "#000",
            
              }}
              className="head_of_products"
            >
            Dream Cream

            </h1>
            <br />
            <h1
              style={{
                textAlign: "start",
                color: "#000",
                fontWeight: "400",
                fontSize: "15px",
              }}
            >
             Dream Cream is a custom-blended topical treatment that is applied directly to the genital area 30 minutes prior to sexual activity. It has the ability to last up to 2 hours and during that time it will increase arousal, sensitivity, and enhance pleasure, allowing for more intense and frequent orgasms.
             <br />    <br />
             Product details: Dream Cream is a specially formulated, prescription arousal cream. It is applied topically, effectively increases blood flow to the genitals. This increases sensitivity and boosts sexual desire.
             <br />    <br />
             What is in this blend:
             <br />    <br />
  <ul>
    <li>Arginine 6% – An amino acid that increases blood flow.
</li>
<br />
<li>Sildenafil 2%– Stimulates blood flow directly to the genitals and improves the overall sexual experience.
</li>


<br />
<li>Apomorphine 3% - works in the central nervous system (CNS) to enhance sexual response due to stimulation.
</li>
  </ul>


            </h1>

            <br />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12" style={{ textAlign: "center" }}>
                      <br />
                      <MDBCardImage
                        src={prod1}
                        alt="..."
                        className="products"
                      />
                    </MDBCol>
                    <MDBCol md="12">
                      <MDBCardBody>
                      
                        <MDBCardTitle
                          style={{
                            color: "#000",
                         
                            textAlign: "center",
                          }}
                          className="head_of_products"
                        >
                          
                        </MDBCardTitle>
                        <br />
                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              QTY
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              Price
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}></Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />1 
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              $105
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}>
                            {" "}
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />{" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",

                                  position: "relative",
                                  top: "-10px",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                className="term_white"
                              >
                                <a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=4b6bf2b8-12b0-4a63-a328-9751d6c5a385"> <button className="btn-grado" >Order Now</button></a>

                               
                              </MDBCardText>
                            </MDBCardText>
                         
                          </Grid>
                        </Grid>
                  

                        <MDBCardText>
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: "300",
                              color: "#000",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>

              <br />
              <br />
              <br />
            </Grid>
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Products;
