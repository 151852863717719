import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/prod1.png";
import prod2 from "./image/prod2.png";
import h1 from "./image/ho1.png";
import h2 from "./image/ho2.png";
import h3 from "./image/ho3.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backs">
          <br />

          <div className=" under_backk">
            <br />
            <br />
            <div className="container">
              <h1
                style={{
                  textAlign: "start",
                  color: "#fff",
                  fontWeight: "700",
                  fontSize: "40px",
                }}
              >
                WHAT WE OFFER
              </h1>
              <br />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12} lg={12} sm={12}>
                  <MDBCard
                    style={{ width: "100%", height: "100%" }}
                    className="background_product_card"
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="5">
                        <MDBCardImage
                          src={prod1}
                          alt="..."
                          style={{ width: "80%", height: "80%" }}
                        />
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBCardBody>
                          <br />
                          <br />
                          <MDBCardTitle
                            style={{
                              color: "#fff",
                              fontWeight: "800",
                              fontSize: "34px",
                            }}
                          >
                            What is Trazodone?
                          </MDBCardTitle>

                          <br />
                          <MDBCardText
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white"
                          >
                            Originally approved as an antidepressant medication,
                            studies have found that trazodone in low doses can
                            help increase the availability of serotonin.
                            Serotonin is used by our bodies to stabilize our
                            mood, feelings of well-being and promotes sleep.
                            Goodbye to sleepless nights, hello good mood!
                          </MDBCardText>
                          <MDBCardText>
                            <br />
                            <small className="text-muted">
                              <button className="btn-grads">Get Started</button>
                            </small>
                            <br />

                            <p
                              style={{
                                textAlign: "start",
                                fontWeight: "300",
                                color: "#fff",
                              }}
                              className="term_white_hair_under"
                            >
                              Prescribed via Digital Physician Visit If
                              Medically Appropriate
                            </p>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </Grid>
                <div className=" back_refund">
                  <br />
                </div>

                <div className="container ">
                  <br /> <br /> <br />
                  <h1
                    style={{
                      textAlign: "start",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "40px",
                    }}
                  >
                    Better sleep in just 3 easy steps.
                  </h1>
                  <br />
                  <Grid container spacing={10}>
                    <Grid item md={4} lg={4} xs={12} sm={12}>
                      <img src={h1} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item md={4} lg={4} xs={12} sm={12}>
                      <img src={h2} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item md={4} lg={4} xs={12} sm={12}>
                      <img src={h3} style={{ width: "100%" }} />
                    </Grid>
                  </Grid>
                  <br />
                </div>
                <br />
                <br />
                <br />
                <Grid item md={12} xs={12} lg={12} sm={12}>
                  <MDBCard
                    style={{ width: "100%", height: "100%" }}
                    className="background_product_card"
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="5">
                        <MDBCardImage
                          src={prod2}
                          alt="..."
                          style={{ width: "80%", height: "80%" }}
                        />
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBCardBody>
                          <br />
                          <br />
                          <MDBCardTitle
                            style={{
                              color: "#fff",
                              fontWeight: "800",
                              fontSize: "34px",
                            }}
                          >
                            Benefits of Trazodone can include...
                          </MDBCardTitle>
                          <br />
                          <br />
                          <MDBCardText
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white"
                          >
                            <DoneOutlineIcon /> Trazodone may be less expensive
                            than some newer insomnia medicines because it is
                            available generically.
                            <br />
                            <DoneOutlineIcon /> In low doses trazadone is
                            generally not felt to be addictive.
                            <br />
                            <DoneOutlineIcon /> Maintain healthy hair
                            <br />
                            <DoneOutlineIcon /> Trazodone is a non-controlled
                            substance unlike some other sleep aids such as
                            benzodiazepines.
                            <br />
                            <DoneOutlineIcon /> Studies have shown that
                            trazodone may be useful as a sleep aid at low doses
                            of 25-50 mg.
                          </MDBCardText>
                          <MDBCardText>
                            <br />
                            <br />
                            <small className="text-muted">
                              <button className="btn-grads">Get Started</button>
                            </small>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </Grid>
              </Grid>
              <br />
              <br />
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className=" back_refund">
            <div className="container">
              <br />
              <br />
              <p
                style={{
                  textAlign: "start",
                  fontWeight: "800",
                  color: "#fff",
                }}
                className="term_white_about"
              >
                How does Trazodone work?
                <br />
                <br />
              </p>

              <p
                style={{
                  textAlign: "start",
                  fontWeight: "300",
                  color: "#fff",
                }}
                className="term_white"
              >
                Trazodone works by increasing the levels of serotonin within
                your brain. By raising the levels of serotonin in the brain,
                trazodone’s job is to increase the levels of this
                neurotransmitter and help you feel relaxed. Within your system,
                trazodone behaves like a shield blocking the reuptake of
                serotonin by the alpha1 adrenergic receptors and H1 histamine
                receptors. Considering its unique properties, in low doses
                trazodone can help promote well-being and better sleep with
                minimal side effects for most people... thats a big win, thanks
                science!
                <br />
                <br />
              </p>
              <br />

              <p
                style={{
                  textAlign: "start",
                  fontWeight: "800",
                  color: "#fff",
                }}
                className="term_white_about"
              >
                Is Trazodone a sleeping pill?
                <br />
                <br />
              </p>

              <p
                style={{
                  textAlign: "start",
                  fontWeight: "300",
                  color: "#fff",
                }}
                className="term_white"
              >
                For patients taking Trazodone, sedative effects may include a
                relaxed, sleepy feeling. Although trazodone was originally
                designed to treat certain forms of depression, studies have
                shown that it may be useful as a nighttime sleep aid when taken
                at low doses for patients struggling with insomnia.
                <br />
                <br />
              </p>

              <br />
              <br />
            </div>

            <br />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Products;
