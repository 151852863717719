import React from "react";
import { Grid } from "@mui/material";
function HeroSection() {
  return (
    <div className="background_productPage">

<div className="container ">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />   <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
   
    </div>
  );
}

export default HeroSection;
