import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/prod1.png";
import prod2 from "./image/prod2.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <br />

        <div className=" under_backk">
          <br />
          <br />
          <div className="container">
            <h1
              style={{
                textAlign: "start",
                color: "#000",
                fontWeight: "700",
                fontSize: "40px",
              }}
            >
              WHAT WE OFFER
            </h1>
            <br />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_male"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#fff",
                            fontWeight: "800",
                            fontSize: "34px",
                          }}
                        >
                          SILDENAFIL
                        </MDBCardTitle>

                        <br />
                        <MDBCardText
                          style={{
                            textAlign: "start",
                            fontWeight: "300",
                            color: "#fff",
                          }}
                          className="term_white"
                        >
                          Sildenafil is a medication primarily used to treat
                          erectile dysfunction (ED) in males. It belongs to a
                          class of drugs called phosphodiesterase type 5 (PDE5)
                          inhibitors. Sildenafil works by increasing blood flow
                          to the penis, which helps to achieve and maintain an
                          erection during sexual stimulation.
                        </MDBCardText>
                        <MDBCardText>
                          <br />
                          <div className="center">
                          <a href="./SILDENAFILTroche" style={{textDecoration:"none"}}> <button className="btn-grad">Sildenafil 110 MG Troche</button></a>
                           
                          
                          </div>
                          <br />

                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_male"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#fff",
                            fontWeight: "800",
                            fontSize: "34px",
                          }}
                        >
                          TADALAFIL
                        </MDBCardTitle>

                        <br />
                        <MDBCardText
                          style={{
                            textAlign: "start",
                            fontWeight: "300",
                            color: "#fff",
                          }}
                          className="term_white"
                        >
                          Tadalafil is a medication primarily used to treat
                          erectile dysfunction (ED) in males. It belongs to a
                          class of drugs called phosphodiesterase type 5 (PDE5)
                          inhibitors. Tadalafil is also used to treat symptoms
                          of benign prostatic hyperplasia (BPH) and pulmonary
                          arterial hypertension (PAH).
                        </MDBCardText>
                        <MDBCardText>
                          <br />
                          <div className="center">
                        <a href="./Tadaliafil20mgTroche" style={{textDecoration:"none"}}> <button className="btn-grad">
                              Tadalafil 20 mg Troche
                            </button></a>
                           
                          
                          </div>
                          <br />

                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>

              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_male"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#fff",
                            fontWeight: "800",
                            fontSize: "34px",
                          }}
                        >
                          COMBINATION
                        </MDBCardTitle>

                        <br />
                        <MDBCardText
                          style={{
                            textAlign: "start",
                            fontWeight: "300",
                            color: "#fff",
                          }}
                          className="term_white"
                        >
                          There is a combination medication available for the
                          treatment of erectile dysfunction (ED) called
                          sildenafil and tadalafil combination. It contains both
                          sildenafil and tadalafil, which are the active
                          ingredients of the individual medications. This
                          combination medication is used to provide an enhanced
                          therapeutic effect by targeting multiple pathways
                          involved in erectile function.
                        </MDBCardText>
                        <MDBCardText>
                          <br />
                          <div className="center">
                            <Grid container>
                            <Grid item md={12} lg={12} xs={12} sm={12} className="centeralldivofmale">
                            <a href="./Sidenadil-Tadaldil" style={{textDecoration:"none"}}>
                              {" "}
                              <button className="btn-grad">
                                Sildenafil + Tadalafil
                              </button>
                            </a>
                                
</Grid>
<Grid item md={12} lg={12} xs={12} sm={12} className="centeralldivofmale">
<a href="./Sidenadil-Papaverine" style={{textDecoration:"none"}}> <button className="btn-grad">
                              Sildenafil + Papaverine
                            </button></a>
                            
                                
</Grid>



<Grid item md={12} lg={12} xs={12} sm={12} className="centeralldivofmale">
<a href="./PEMaxTadalafil-Apomorphine" style={{textDecoration:"none"}}>    <button className="btn-grad">
                              Tadalafil + Apomorphine
                            </button></a>
                            
                                
</Grid>


<Grid item md={12} lg={12} xs={12} sm={12} className="centeralldivofmale">
 
<a href="./PT141-Tadalafil-Oxytocin" style={{textDecoration:"none"}}>  <button className="btn-grad">
                              PT-141 + Tadalafil + Oxytoxin
                            </button></a>
                            
                                
</Grid>

                            </Grid>
                           
                        
                           
                          </div>
                          <br />
                       
                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>
              <br />
              <br />
              <br />
            </Grid>
            <br />
            <br />
          </div>
        </div>
      
      </div>
    </>
  );
}

export default Products;
