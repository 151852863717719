import React from "react";

function HeroSection() {
  return (
    <div className="background_comb_med_one">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className="hone" style={{ textAlign: "start", color: "#fff" }}>
          TADALAFIL
          <span style={{ color: "#3cb1a0",}}>
            <br />
            Tadalafil 40 mg Troche
          </span>
        </h1>
        <br />

        <br />

        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
