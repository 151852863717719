import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Navbar from "../Component/Navbar";
import DesktopNavbar from "../Component/DesktopNavbar";
import HeroSection from "../Component/Aboutus/HeroAbout";
import UnderHero from "../Component/Aboutus/UnderAbout";
import ExecutiveTeam from "../Component/Aboutus/ExecutiveTeam";
import Footer from "../Component/HOME/Footer";
import Thefutureoftele from "../Component/Aboutus/Thefutureoftelemedicine";
import MedicalAdvisory from "../Component/Aboutus/MedicalAdvisory";

function Garden() {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="back" style={{ background: "" }}>
          <DesktopNavbar />
          <HeroSection />
          <UnderHero />
         
          <Thefutureoftele />
          <MedicalAdvisory />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div className="back" >
          <Navbar />
          <br/><br/>
          <HeroSection />
          <UnderHero />
     
          <Thefutureoftele />
          <MedicalAdvisory />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
  );
}

export default Garden;
