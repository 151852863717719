import React from "react";
import one from "./image/got.jpeg";
import { Grid } from "@mui/material";
function oneUnderWAYTLess() {
  return (
    <div>
      <div className="container">
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <img src={one} style={{ width: "100%", height: "100%" }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12} xs={12} sm={12}>
            <br />

            <h1
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "800",
                fontSize: "50px",
              }}
            >
              Nu Image Privacy Policy
            </h1>

            <br />


            <p
            style={{
              textAlign: "start",
              fontWeight: "300",
              color: "#fff",
            }}
            className="term_white"
          >
            Our Website is not intended for children under 18 years of age. No
            one under age 18 may provide any information to or through the
            Website. We do not knowingly collect Personal Data from children
            under 18. If you are under 18, do not use or provide any
            information on our Website or on or through any of their features,
            including your name, address, telephone number, email address, or
            any screen name or user name you may use. If we learn we have
            collected or received Personal Data from a child under 18 without
            verification of parental consent, we will delete that information.
            If you believe we might have any information from a child under
            18, please contact us at support@nuimagemedical.com or call us at
            (888)-520-3438.
            <br />
            <br />
          </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default oneUnderWAYTLess;
