import React from "react";

import { Grid } from "@mui/material";

const UnderHero = () => {
  return (
    <div className="">
      <div className=" under_backs">
        <br />

        <div className=" under_backk">
          <br />
          <br />

          <br />

          <h1
            style={{
              textAlign: "center",
              color: "#000",
              fontWeight: "800",
              fontSize: "50px",
            }}
          >
            CANCELLATION/REFUND POLICY
          </h1>

          <br />

          <br />
          <div className="container">
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
              All purchases of BodyLogic treatments are final because
              our products are medications that, once packaged for you, cannot
              be used for another patient due to our pharmacy's regulations.
              Prescription medications cannot be returned for reimbursement, and
              thus we are unable to accept returns for refunds, even if they are
              unused.
            </p>

            <br />
          </div>

          <br />

          <div className=" back_refund">
            <div className="container">
              <br />
              <br />
              <p
                style={{
                  textAlign: "start",
                  fontWeight: "300",
                  color: "#fff",
                }}
                className="term_white"
              >
                However, we want you to receive high-quality products that are
                ready to use. Therefore, we will replace items if they are
                defective or damaged. If you need to exchange a product, please
                submit a ticket via online form, and we will coordinate with you
                to exchange your order.
                <br />
                <br />
                For auto-renewing products and services, your payment method
                will be automatically charged at regular intervals as described
                during the checkout process until you cancel your subscription.
                You may cancel your auto-refills at any time up to forty-eight
                (48) hours before the applicable renewal processing date of your
                auto-refill through the Service logging into your account and
                selecting the cancel option.
                <br />
                <br />
                Please note that our Refund and Return Policy is part of our
                Terms of Service agreement and can be viewed here.
              </p>
              <br />
              <br />
            </div>

            <br />
          </div>
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};

export default UnderHero;
