import React from "react";
import "./UnderCard.css";
import { Grid } from "@mui/material";
import "./Testosteronr.scss";
import gym from "../image/man.jpg";
import evert from "../image/woman.jpg";
import thumb from "./Image/thumb.png";
import right from "./Image/right.png";
import cus from "./Image/ser.jpg";
const UnderHero = () => {
  return (
    <>
      <div className="">
        <div className=" under_backk">
        
        <div className=" under_backk back_of_grey"><div className="container">

<h1
  style={{
    textAlign: "center",
    color: "#000",
   
  }}
  className="head_of_products"
><br />
Experience the Convenience<br />
of Telemedicine
</h1>
<br />
<div className="center_thumbb"><img src={thumb}  className="thumb_image_home"/></div>

<br />
          <p
            style={{
              textAlign: "center",
       
              color: "#000",
            
      
            }}

            className="margin_container head_of_products_text"
          >
     At BodyLogic, we understand that seeking treatment for erectile dysfunction can be a sensitive issue. That's why we offer a discreet and convenient telemedicine platform that allows you to consult with certified doctors from the comfort and privacy of your own home. Our telemedicine service ensures you get personalized and confidential care . When it comes to your health, you deserve the best care possible. Our team of experienced and board-certified doctors specializes in men's health and with BodyLogic, you can rest assured that you are consulting with professionals who have the knowledge and expertise to guide you towards the most effective treatment options tailored to your unique needs.
          </p>

<br />
         
          <br />


</div></div>

 {/*          <h1
  style={{
    textAlign: "center",
    color: "#000",

  }}
  className="head_of_products"
>
Certified Doctors     <br />
for Your Peace of Mind
</h1><br /><br />
<div className="container">

<Grid container spacing={2}>
   
<Grid item md={6} lg={6} xs={12} sm={12}>
<div className="center_thumbd"><img src={right} style={{width:"100%"}}/></div>

          
</Grid>
<Grid item md={6} lg={6} xs={12} sm={12}>
         
         <p className="center_thumbs margin_containerthree head_of_products_text certified_doctorro">When it comes to your health, you deserve the best care possible. Our team of experienced and board-certified doctors specializes in men's health and erectile dysfunction. With BodyLogic, you can rest assured that you are consulting with professionals who have the knowledge and expertise to guide you towards the most effective treatment options tailored to your unique needs.</p>
                 
       </Grid>
        </Grid>

</div> */}

 



<div className="back_of_grey">
<div className="container">

      
          <h1
            style={{
              textAlign: "center",
              color: "#000",
          
            }}
            className="head_of_products"
          >
            Treatments for Sexual Health.
          </h1>

          <br />
          <p
            style={{
              textAlign: "center",
           
              color: "#000",
           
            }}
            className="margin_container head_of_products_text"
          >
            Our discreet and professional care provides you with the ability to
            access generic FDA approved and compounded prescription medications
            right from the comfort of your home, allowing you to achieve your
            desired results and address your concerns with ease.
          </p>
<div className="container"> 

 <Grid container spacing={2}>
            <Grid item md={6} lg={6} xs={12} sm={6}>
              <div class="nft homecard_1">
                <div class="main">
                  <br />
                  <h5 className="men ">Sexual Dysfunction For Men</h5>
                  <br />
                  <img class="tokenImage" src={gym} alt="NFT" />

                  <p class="description head_of_products_text">
                    We didn't forget about you, Men. Patients are raving about
                    improved sensitivity, enjoyment, and fun they have
                    experienced! Find out what all the fuss is about.
                  </p>
                  <hr />
                  <div className="centerall">
                  <a href="./Male" style={{textDecoration:"none"}}> <button className="btn-gradomf" >For Male</button> </a>
                   </div>
                </div>
              </div>
            </Grid>
            <Grid item md={6} lg={6} xs={12} sm={6} className="play_with_cards_male_female" >
              <div class="nft homecard_2">
                <div class="main">
                  <br />
                  <h5 className="men ">Sexual Dysfunction For Ladies</h5>
                  <br />
                  <img class="tokenImage" src={evert} alt="NFT" />

                  <p class="description head_of_products_text">
                    We didn't forget about you, ladies. Patients are raving
                    about improved sensitivity, enjoyment, and fun they have
                    experienced! Find out what all the fuss is about.
                  </p>
                  <hr />
                  
                  <div className="centerall">
                  <a href="./Female" style={{textDecoration:"none"}}> <button className="btn-gradomf" >For Female</button> </a>
                   </div>
              
                </div>
              </div>
            </Grid>
          </Grid>
</div>
        
      


<h1
style={{
textAlign: "center",
color: "#000",

}}
className="head_of_products positions_of_customerservice"
>
Customer Service <br />
That Exceeds Expectations
</h1>

<div className="container">

<Grid container spacing={2} >

<Grid item md={6} lg={6} xs={12} sm={12}>
<div className="positions_of_customerservice_image"><img src={cus} style={{width:"100%"}} className="certified_doctor "/></div>


</Grid>
<Grid item md={6} lg={6} xs={12} sm={12}>

<br/> 
<p className=" margin_containert head_of_products_text " >At BodyLogic, our commitment to your satisfaction is unwavering. Our dedicated customer service team is here to assist you at every step of your journey. Whether you have questions about our services, need assistance with your order, or require guidance on using your prescribed medication, we are just a phone call or email away. Expect prompt, friendly, and supportive assistance from our caring team.</p>

</Grid>


</Grid><br /><br />
</div><br />
</div>

  
</div>
       
        </div>
  
      <br /><br />
      </div>
    </>
  );
};

export default UnderHero;
