import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/produ.jpeg";
import prod2 from "./image/prod2.png";
import Divider from "@mui/material/Divider";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backk">
          <br />
          <br />
          <div className="container">
            <h1
              style={{
                textAlign: "start",
                color: "#000",
            
              }}
              className="head_of_products"
            >
   PT - 141 Combo for Women

            </h1>
            <br />
            <h1
              style={{
                textAlign: "start",
                color: "#000",
                fontWeight: "400",
                fontSize: "15px",
              }}
            >
           (PENDING GENERAL DESCRIPTION)
             <br />    <br />
             Product Details: 
             <br />    <br />
             What’s in the blend:
             <br />    <br />
  <ul>
    <li>Tadalafil 2.5 mg. This effectively increases blood flow to the genitals.
</li>
<br />
<li>Oxytocin 100 iu:  AKA The Love Hormone. recent studies have begun to investigate oxytocin's role in various behaviors, including orgasm and sexual excitement.

</li>


<br />
<li>PT-141 2mg: Has been known to Significantly increase sexual desire and sexual arousal
</li>
  </ul>


            </h1>

            <br />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12" style={{ textAlign: "center" }}>
                      <br />
                      <MDBCardImage
                        src={prod1}
                        alt="..."
                        className="products"
                      />
                    </MDBCol>
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#000",
                       
                            textAlign: "center",
                          }}
                          className="head_of_products"
                        >
                       
                        </MDBCardTitle>
                        <br />
                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              QTY
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              Price
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}></Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br /> 10 TROCHES
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              $17.00 a TROCHE
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}>
                            {" "}
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />{" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",

                                  position: "relative",
                                  top: "-10px",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                className="term_white"
                              >
                                {/* <a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=03ea753a-489e-4cd8-9fd7-96d7d248e608">

                                <button className="btn-grado">Order Now</button>
                                </a>
                                
                                  <button className="btn-grado" onClick={() => displayQtyInput(1, 10)}>Order Now</button>
                                */}
<a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=5b1341eb-ead9-4e75-9c53-379ca48022e9">


<button className="btn-grado" >Order Now</button>

</a>
                              
                               
                              </MDBCardText>
                            </MDBCardText>
                          </Grid>
                        </Grid>
                        <Divider variant="middle" className="divider" />

                        <Grid container spacing={2}>
                        
                       
                          <Grid item md={4} lg={4} xs={12} sm={12}>
                            {" "}
                     
                            
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                20 TROCHES
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $15.00 a TROCHE
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                  <>
<a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=87b9be9c-ebdf-4898-ac00-93869cbd09f5">

<button className="btn-grado" >Order Now</button>

</a>

</>
                                </MDBCardText>
                              </MDBCardText>
                              <Divider variant="middle" className="divider" />
                            </Grid>
                          </Grid>
                    

                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                               30 TROCHES
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $13.00 a TROCHE
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                     <>
<a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=cf0908da-2a59-428c-85b0-657cc53f6642">


<button className="btn-grado" >Order Now</button>
</a>

</>
                                </MDBCardText>
                              </MDBCardText>
                              <Divider variant="middle" className="divider" />
                            </Grid>
                          </Grid>
                         
                        </Grid>

                        <MDBCardText>
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: "300",
                              color: "#000",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>

              <br />
              <br />
              <br />
            </Grid>
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Products;
