import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/prod1.png";
import prod2 from "./image/prod2.png";
import h1 from "./image/ho1.png";
import h2 from "./image/ho2.png";
import h3 from "./image/ho3.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backs">
          <br />

          <div className=" under_backk">
            <br />
            <br />
            <div className="container">
              <h1
                style={{
                  textAlign: "start",
                  color: "#fff",
                  fontWeight: "700",
                  fontSize: "40px",
                }}
              >
                WHAT WE OFFER
              </h1>
              <br />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12} lg={12} sm={12}>
                  <MDBCard
                    style={{ width: "100%", height: "100%" }}
                    className="background_product_card"
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="5">
                        <MDBCardImage src={prod1} alt="..." />
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBCardBody>
                          <br />
                          <br />
                          <MDBCardTitle
                            style={{
                              color: "#fff",
                              fontWeight: "800",
                              fontSize: "34px",
                            }}
                          >
                            Hair loss does not discriminate
                          </MDBCardTitle>
                          <br />
                          <br />
                          <MDBCardText
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white"
                          >
                            Regardless of gender or ethnicity, hair loss is a
                            universal problem that often causes people to feel
                            insecure and self-conscious about their appearance.
                            The look of premature aging leaves many people with
                            less confidence than they had, but positive change
                            is possible.
                            <br />
                            <br />
                            NuDew®, our innovative and medically-supervised
                            hair-loss solution, may help boost your hair growth.
                            The good news is hair loss no longer has to define
                            you or rob you of your confidence!
                          </MDBCardText>
                          <MDBCardText>
                            <br />
                            <br />
                            <small className="text-muted">
                              <button className="btn-grads">Get Started</button>
                            </small>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </Grid>

                <br />

                <br />
                <div className="container ">
                  <br /> <br /> <br />
                  <h1
                    style={{
                      textAlign: "start",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "40px",
                    }}
                  >
                    Three powerful ingredients
                  </h1>
                  <br />
                  <Grid container spacing={10}>
                    <Grid item md={4} lg={4} xs={12} sm={12}>
                      <img src={h1} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item md={4} lg={4} xs={12} sm={12}>
                      <img src={h2} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item md={4} lg={4} xs={12} sm={12}>
                      <img src={h3} style={{ width: "100%" }} />
                    </Grid>
                  </Grid>
                  <br />
                  <p
                    style={{
                      textAlign: "start",
                      fontWeight: "300",
                      color: "#fff",
                    }}
                    className="term_white"
                  >
                    Unlike other over-the-counter products, NuDew® is
                    physician-prescribed and professionally supervised, making
                    it superior choice for hair growth.
                    <br />
                  </p>
                </div>
                <br />
                <br />
                <br />
                <Grid item md={12} xs={12} lg={12} sm={12}>
                  <MDBCard
                    style={{ width: "100%", height: "100%" }}
                    className="background_product_card"
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="5">
                        <MDBCardImage
                          src={prod2}
                          alt="..."
                          style={{ width: "80%", height: "80%" }}
                        />
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBCardBody>
                          <br />
                          <br />
                          <MDBCardTitle
                            style={{
                              color: "#fff",
                              fontWeight: "800",
                              fontSize: "34px",
                            }}
                          >
                            Benefits of NuDew® and NuDew Max® can include
                          </MDBCardTitle>
                          <br />
                          <br />
                          <MDBCardText
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white"
                          >
                            <DoneOutlineIcon /> Prevent hair loss
                            <br />
                            <DoneOutlineIcon /> increase hair loss
                            <br />
                            <DoneOutlineIcon /> Maintain healthy hair
                            <br />
                            <DoneOutlineIcon /> Restore confidence in your
                            appearance
                            <br />
                            <DoneOutlineIcon /> 3-in-1 OR 4-in-1 cost effective
                            treatments
                          </MDBCardText>
                          <MDBCardText>
                            <br />
                            <br />
                            <small className="text-muted">
                              <button className="btn-grads">Get Started</button>
                            </small>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </Grid>
              </Grid>
              <br />
              <br />
            </div>
          </div>
          <br />
          <br />

          <div className=" back_refund">
            <div className="container">
              <br />
              <br />
              <p
                style={{
                  textAlign: "start",
                  fontWeight: "800",
                  color: "#fff",
                }}
                className="term_white_about"
              >
                Maximize those folicles fellas with NuDew Max®
                <br />
                <br />
              </p>

              <p
                style={{
                  textAlign: "start",
                  fontWeight: "300",
                  color: "#fff",
                }}
                className="term_white"
              >
                We’ve maximized NuDew® with the added benefits of dutasteride to
                form NuDew Max®, specifically for the fellas. Available in a gel
                application, this formulation consists of topical minoxidil,
                ketoconazole, finasteride plus dutasteride.
                <br />
                <br />
                Dutasteride can help with the reduction of hair loss and even
                regrow some of your lost hair. NuDew Max® is packed with all the
                essentials needed to prevent further hair loss, while maximizing
                your growth potential
                <br />
                <br />
              </p>
              <br />

              <button className="btn-grads">Get Started</button>

              <br />
              <br />
            </div>

            <br />
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Products;
