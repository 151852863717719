import React from "react";
import { Grid } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";
import ex1 from "./Image/real.jpeg";
import ex2 from "./Image/reall.jpeg";

function ExecutiveTeam() {
  return (
    <div  style={{background:"#fff"}}>
   
   <div className="container">
   <br />

      <h1
        style={{
          textAlign: "center",
          color: "#000",
      
        }}
            className="term_white_about"
      >
  Discover the BodyLogic Difference Today
      </h1>
      <br />
<br />
          <p
            style={{
              textAlign: "center",
        
              color: "#000",
            }}
            className="term_white"
          >
       Choose BodyLogic as your trusted partner in overcoming erectile dysfunction and reclaiming your vitality.
       <br />     <br />
Our telemedicine platform, expert certified doctors, exceptional customer service, fast prescription delivery, and discreet approach make us the ideal choice for your sexual health needs.
<br />     <br />
Start your confidential consultation now and embark on a journey to improved performance and satisfaction. Experience the BodyLogic difference today!
          </p>

<br />
<br />
<div className="centerall"> <a href="ProductPage" style={{textDecoration:"none"}}> <button className="btn-grado" >GET STARTED</button></a></div>
<br />
<br />
<br /> <br /> <br />










</div>
    </div>
  );
}

export default ExecutiveTeam;
