import React from "react";
import "./UnderCardsWayTLess.scss";
import prod1 from "./image/prod1.png";
import prod3 from "./image/prod3.png";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Grid } from "@mui/material";

function UnderCardsWayTLess() {
  return (
    <div>

    
      <div className=" back_refund">
        <div className="container">
          <br />
          <br />
          <h1
            style={{
              textAlign: "start",
              fontWeight: "800",
              color: "#fff",
            }}
          >
            A power combo for maintenance
          </h1>
          <br />
          <p
            style={{
              textAlign: "start",
              fontWeight: "300",
              color: "#fff",
            }}
            className="term_white"
          >
            After successfully completing your WAYT-tain™ program, this trio is
            the perfect ally to help you transition into your new lifestyle. You
            will be increasing the number of foods you can eat, and this combo
            can help stabilize your weight. This trio may aid in appetite
            suppression, craving control, blood sugar control, and insulin
            regulation, but it won't make a difference without proper diet and
            exercise modifications.
            <br />
            <br />
          </p>
          <br />
          <br />
        </div>

        <br />
      </div>

      <div className="container">
        <Grid container spacing={2}>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            {" "}
            <li class="cards_item" id="item_salad">
              <div class="cards">
                <div class="card_image">
                  <img
                    src={prod1}
                    alt="mixed vegetable salad in a mason jar. "
                  />
                </div>
                <div class="card_content">
                  <h6 class="card_title">
                    You lost the weight, now it's time to WAYT-tain
                  </h6>
                  <div class="card_text">
                    <p>
                      The WAYT-tain™ E-book has everything you need to know
                      about your weight loss maintenance and grants you access
                      to:
                    </p>
                    <p>
                      <DoneAllIcon />
                      Detailed breakdown of our Wayt-tain™ product.
                      <br />
                      <DoneAllIcon />
                      Benefits and science of how it works.
                      <br />
                      <DoneAllIcon />
                      Detailed breakdown of our Wayt-tain™ product.
                      <br />
                      <DoneAllIcon />
                      Benefits and science of how it works.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </Grid>

          <Grid item md={6} lg={6} xs={12} sm={12}>
            {" "}
            <li class="cards_item" id="item_salad">
              <div class="card">
                <div class="card_image">
                  <img
                    src={prod3}
                    alt="mixed vegetable salad in a mason jar. "
                  />
                </div>
                <div class="card_content">
                  <h6 class="card_title">Food Delivery Busy Schedule?</h6>
                  <div class="card_text">
                    <p>
                      Don’t worry, our nationwide food delivery service has got
                      you covered!
                    </p>
                    <p>
                      <div className="center">
                        {" "}
                        <button className="btn-grads">Get Started</button>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default UnderCardsWayTLess;
