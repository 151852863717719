import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./UnderCard.css";
import { Grid } from "@mui/material";
import "./Testosteronr.scss";
import gym from "../image/test.jpeg";
import evert from "../image/evert.jpeg";
const UnderHero = () => {
  return (
    <div className="container">
      <div className=" under_backs">
       

        <h1
style={{
textAlign: "center",
color: "#000",

}}
className="head_of_products"
>
DISCOVER THE EFFECTIVENESS<br />
OF UNIQUE COMBINATIONS
</h1>
<br />
                  <br />
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            sm={12}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card className="back_of_top_cards_hero">
              <div className="container">
                <br />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ textAlign: "center", fontWeight: "600" }}
                  >
                    COMBINATION 1
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      textAlign: "center",
                 
                      color: "#000",
                    }}
                    className="head_of_products_text"
                  >
                    Sildenafil 100 milligram + <br/> Tadalafil 40 milligram Troche
                  </Typography>
                </CardContent>
                <CardActions className="MobileTestosterone_btn_grad" style={{
                      textAlign: "center",
                 justifyContent:"center",
                 
    
                    }}>
                <a href="./Sidenadil-Tadaldil">
                    {" "}
                    <button className="btn-grad">
                      LEARN MORE
                    </button>
                  </a>
                </CardActions>
              </div>
            </Card>
          </Grid>

          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            sm={12}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card className="back_of_top_cards_hero">
              <div className="container">
                <br />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ textAlign: "center", fontWeight: "600" }}
                  >
                    COMBINATION 2
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{
                      textAlign: "center",
                 
                      color: "#000",
                    }}
                    className="head_of_products_text"
                  >
                    PE Max Tadalafil 75mg +<br/> Apomorphine 10 mg Troche
                  </Typography>
                </CardContent>
                <CardActions className="MobileTestosterone_btn_grad" style={{
                      textAlign: "center",
                 justifyContent:"center",
                 
    
                    }}>
                  <a href="./PEMaxTadalafil-Apomorphine">
                    {" "}
                    <button className="btn-grad">
                      LEARN MORE 
                    </button>
                  </a>
                </CardActions>
              </div>
            </Card>
          </Grid>
        </Grid>
        <br />
      </div>
    </div>
  );
};

export default UnderHero;
