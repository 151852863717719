import React from "react";
import { Grid } from "@mui/material";
function HeroSection() {
  return (
    <div className="background_hero_ed">
      <div className="container ">
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br /> <br />
        <Grid container spacing={2}>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            {" "}
            <h1 className="hone">Let's Get Started!</h1>
            <br />
            <p className="pone">
              MEDICAL ASSESSMENT
              <br />
            </p>{" "}
            <br /> <br /> <br />
          </Grid>
        </Grid>
        <br /> <br /> <br />
        <br />
        <div className="center"> </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
