import React from "react";

function HeroSection() {
  return (
    <div className="background_WayLess">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className="hone" style={{ textAlign: "start" }}>
          WAYT-less™ <br />
          <span style={{ color: "#3cb1a0", fontSize: "32px" }}>
            <br />
          </span>
        </h1>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white"
        >
          Your WAYT-less™ journey begins here. <br />
          WAYT-less™ can potentially control cravings
        </p>
        <br />
       

        <button className="btn-grad">Get Started</button>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
