import React from "react";

function HeroSection() {
  return (
    <div className=" ">
      <div className="container">
       
        <br />  
        <br />
        <br />
        <h1 className="honecontact" style={{ textAlign: "center" }}>
          Got a question?
          <span style={{ color: "#3cb1a0", fontSize: "22px" }}>
            <br />
            We're here to help and answer any questions you might have.
          </span>
        </h1>
        <br />  
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
