import React from "react";

import { Grid } from "@mui/material";

const UnderHero = () => {
  return (
    <div className="">
      <div className=" under_backs">
        <br />

        <div className=" under_backk">
          <br />
          <br />

          <br />

          <h1
            style={{
              textAlign: "center",
              color: "#fff",
              fontWeight: "800",
              fontSize: "50px",
            }}
          >
            NOTICE OF PRIVACY PRACTICES – MD INTEGRATIONS
          </h1>

         <br/>
         <br/>
          <div className="container">
            <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#3CD3AD",
              }}
              className="term_white"
            >
              AFFILIATED COVERED ENTITY
              <br />
            </p>
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#fff",
              }}
              className="term_white"
            >
              <span
                style={{
                  textAlign: "start",
                  fontWeight: "800",
                  color: "#fff",
                }}
              >
                {" "}
                THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE
                USED ANDDISCLOSED AND HOW YOU CAN GET ACCESS TO THIS
                INFORMATION. PLEASE REVIEW IT CAREFULLY.
              </span>
              <br />
              <br />
              This Notice of Privacy Practices (the “Notice”) describes how MD
              Integrations, P.A. and the members of its Affiliated Covered
              Entity (collectively “we” or “our”) may use and disclose your
              protectedhealth information to carry out treatment, payment or
              business operations and for other purposes that arepermitted or
              required by law. An Affiliated Covered Entity is a group of health
              care providers undercommon ownership or control that designates
              itself as a single entity for purposes of compliance with the
              Health Insurance Portability and Accountability Act (“HIPAA”). We
              have elected to voluntarily substantially comply with the
              standards set forth in HIPAA. The members of the MD Integrations
              Affiliated Covered Entity will share protected health information
              with each other for the treatment, payment, and health care
              operations of the MD Integrations Affiliated Covered Entity and as
              permitted by HIPAA and this Notice of Privacy Practices. For a
              complete list of the members of the MD Integrations Affiliated
              Covered Entity, please contact the MD Integrations, P.A. Privacy
              Office.
              <br />
              <br />
              “Protected health information” or “PHI” is information about you,
              including demographic information, that may identify you and that
              relates to your past, present or future physical health or
              condition, treatment or payment for health care services. This
              Notice also describes your rights to access and control your
              protected health information.
            </p>
          </div>

          <br />
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};

export default UnderHero;
