import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/prod1.png";
import prod2 from "./image/prod2.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="" style={{background:"#fff"}}>
      

      <div className=" under_backk">
       
       <div className="  back_of_grey">
       <br />
          <div className="container">
          <h1
  style={{
    textAlign: "center",
    color: "#000",
   
  }}
  className="head_of_productsP"
> 
<span style={{fontWeight:"900"}}>GET STARTED</span>




</h1>
<h1
  style={{
    textAlign: "center",
    color: "#000",
   
  }}
  className="head_of_products"
> 

<span style={{fontWeight:"700"}}   >4 Simple & Easy Steps</span>

</h1>

        
            <br />

<p   className="head_of_productstt"  style={{
    textAlign: "center",
  
   
  }}>

1. CHOOSE YOUR PREFERRED TREATMENT OPTION


  </p>


  <p   className="head_of_productstt"  style={{
    textAlign: "center",
  
   
  }}>

2. TELL US ABOUT YOURSELF


  </p>

  <p   className="head_of_productstt"  style={{
    textAlign: "center",
  
   
  }}>

3. GET YOUR PRESCRIPTION APPROVED BY A DOCTOR


  </p>

  <p   className="head_of_productstt"  style={{
    textAlign: "center",
  
   
  }}>

4. RECEIVE YOUR TREATMENT IN THE MAIL


  </p> <br />
          </div>
        </div>
        </div>
        <br /> <br />


        <h1
  style={{
    textAlign: "center",
    color: "#000",
   
  }}
  className="head_of_productsP"
> 
<span style={{fontWeight:"900"}}>TREATMENTS <br /> FOR MALE</span>
</h1>
        <br />

        <div className="container">
           
            <br />
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} lg={6} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_maleX"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#000",
                            fontWeight: "800",
                            fontSize: "34px",
                            textAlign:"center"
                          }}
                        >
                          SILDENAFIL
                        </MDBCardTitle>

                        <br />
                       
                        <MDBCardText>
                          <br />
                          <div className="center">
                            {" "}
                            <a href="./SILDENAFILTroche" style={{textDecoration:"none"}}>   <button className="btn-grad">SILDENAFIL 110 MG Troche</button></a>
                         
                          
                          </div>
                          <br />

                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>
              <Grid item md={6} xs={12} lg={6} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_maleX"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#000",
                            fontWeight: "800",
                            fontSize: "34px",
                            textAlign:"center"
                          }}
                        >
                          TADALAFIL
                        </MDBCardTitle>

                        <br />
                       
                        <MDBCardText>
                          <br />
                          <div className="center">
                          <a href="./Tadaliafil20mgTroche" style={{textDecoration:"none"}}><button className="btn-grad" >
                          TADALAFIL 20 MG TROCHE
                            </button></a>
                            
                          
                          </div>
                          <br />

                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>

              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_maleX"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#000",
                            fontWeight: "800",
                            fontSize: "34px",
                            textAlign:"center"
                          }}
                        >
                          COMBINATION
                        </MDBCardTitle>

                        <br />
                       
                        <MDBCardText>
                          <br />
                          <div className="center">
                            <Grid container>
                            <Grid item md={6} lg={6} xs={12} sm={12} className="buttonAligns">
                            <a href="Sidenadil-Tadaldil" style={{textDecoration:"none"}}>
                              {" "}
                              <button className="btn-grad">
                                Sildenafil + Tadalafil
                              </button>
                            </a>

                              
</Grid>

<Grid item md={6} lg={6} xs={12} sm={12} className="buttonAlignst">
<a href="Sidenadil-Papaverine" style={{textDecoration:"none"}}>     <button className="btn-grad">
                              Sildenafil + Papaverine
                            </button></a>
                              
</Grid>
                            </Grid>
                          
                          
                       
                          </div>
                          <br />
                          <div className="center">

                          <Grid container>
                            <Grid item md={6} lg={6} xs={12} sm={12} className="buttonAligns">
                            <a href="PEMaxTadalafil-Apomorphine" style={{textDecoration:"none"}}>    <button className="btn-grad">
                              Tadalafil + Apomorphine
                            </button></a>

                              
</Grid>

<Grid item md={6} lg={6} xs={12} sm={12} className="buttonAlignst">
<a href="PT141-Tadalafil-Oxytocin" style={{textDecoration:"none"}}>   <button className="btn-grad">
                              PT-141 + Tadalafil + Oxytoxin
                            </button></a>
                              
</Grid>
                            </Grid>
                         
                        
                        
                         
                          </div>
                          <br />
                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>
              <br />
              <br />
              <br />
            </Grid>
            <br />
            <br />
          </div>


        


<Grid item md={12} xs={12} lg={12} sm={12}>

                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_maleXG"
                >

<h1
  style={{
    textAlign: "center",
    color: "#000",
   
  }}
  className="head_of_productsP"
> <br /> <br />
<span style={{fontWeight:"900"}}>TREATMENTS <br /> FOR FEMALE</span>
</h1>
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                       
                        <br />
                       
                        <MDBCardText>
                          <br />
                          <div className="center">
                          <a href="DreamCream" style={{textDecoration:"none"}}> 
                              {" "}
                              <button className="btn-grad">
                              Dream Cream
                              </button>
                            </a>
                           
                            <a href="PT141-Tadalafil-Oxytocin" style={{textDecoration:"none"}}>
                              
                            <button className="btn-grad">
                              PT - 141 COMBO 
                            </button>
                              </a> 
                           
                          </div>
                          <br />
                       
                          <br />
                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                           <br />
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>



      </div>
    </>
  );
}

export default Products;
