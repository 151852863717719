import React from "react";

function HeroSection() {
  return (
    <div className=" background_dream_creamtwo">

<div className="container">
        <br />
        <br />
        <br />
        <br />
     
        <br />
        <h1 className="hone" style={{ color: "#fff" }}>
     PT - 141 
          <span style={{ color: "#3cb1a0", fontSize:"25px"}}>
            <br />
            Combo for Women

          </span>
        </h1>
        <br />
        <h1 className="honeoi" style={{ color: "#fff" }}>
        Results within 15 - 20 minutes <br/>
         with Fast Acting Sublingual Dissolving Troches
        </h1>
        <br />
        <p
          style={{
           
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
        <br />
        <br />
        <br />
        <br /> <br />
      </div>
   
    </div>
  );
}

export default HeroSection;
