import React from "react";
import { Grid } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";
import ex1 from "./Image/Doctor-Edmonton.jpg";
import ex2 from "./Image/Doctor-Trotta.jpg";
import ex3 from "./Image/realll.jpeg";
function ExecutiveTeam() {
  return (
    <div  style={{background:"#fff"}}>
      <div className="container" >
        <p
          style={{
            textAlign: "center",
            fontWeight: "800",
            color: "#3CD3AD",
          }}
          className="term_white_exe"
        >
          Executive Team
          <br />
        </p>
        <br />
        <Grid container spacing={3}>
          <Grid item md={6} lg={6} xs={12} sm={6}>
            <MDBCard className="back_exec_card">
              <MDBCardImage src={ex1} position="top" alt="..." />
              <MDBCardBody>
                <MDBCardTitle> Doctor-Edmonton</MDBCardTitle>
                <hr />
                <MDBCardTitle>Founder & Chief Executive Officer</MDBCardTitle>
                <MDBCardText    className="head_of_products_text">
                  Recognized as a technology leader with an entrepreneurial
                  drive for crafting the requisite strategic vision to achieve
                  business goals, Andreas Dettlaff offers a unique blend of
                  executive acumen and IT solutions development credited with
                  strong technical and operational improvement at BODYLOGIC
                  HEALTH AND WELLNESS®.
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={6}>
            <MDBCard className="back_exec_card">
              <MDBCardImage src={ex2} position="top" alt="..." />
              <MDBCardBody>
                <MDBCardTitle> Doctor-Trotta</MDBCardTitle>
                <hr />
                <MDBCardTitle>Chief Operating Officer</MDBCardTitle>
                <MDBCardText    className="head_of_products_text">
                  Alejandra Pepper serves as the Chief Operations Officer for
                  BODYLOGIC HEALTH AND WELLNESS®. She is in charge of overseeing
                  the overall success of the company by providing direction to
                  the people who help build and maintain BODYLOGIC HEALTH AND
                  WELLNESS®.
                </MDBCardText>
                <br />
              </MDBCardBody>
            </MDBCard>
          </Grid>
        </Grid>

        <br />
        <br />
   
      </div>
    </div>
  );
}

export default ExecutiveTeam;
