import React from "react";

import { Grid } from "@mui/material";
import h1 from "./Image/h1.png";
import h2 from "./Image/h2.png";
import h3 from "./Image/h3.png";
const UnderHero = () => {
  return (
    <div className="">
      <div className=" under_backs">
        <br />
        <br />
        <br />

        <div className=" back_refund">
          <div className="container">
            <br />
            <br />
            <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                color: "#fff",
              }}
              className="term_white_about"
            >
              The future of telemedicine
              <br />
              <br />
            </p>

            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#fff",
              }}
              className="term_white"
            >
              At Body Logic Health®, our mission is to provide superior medical
              assistance as well as patient access to quality prescription
              medication and supplements more conveniently and easily without
              the inconvenience and costs of visiting a physician's office. We
              trust and take pride in our products and services and look forward
              to helping you live a longer and healthier life.
              <br />
              <br />
            </p>
            <br />
            <br />
          </div>

          <br />
        </div>
        <br />
        <br />
        <div className="container under_backk">
        
          <br />
          <h1
            style={{
              textAlign: "center",
              color: "#000",
         
            }}
            className="term_white_about"
          >
            How Our Telemedicine Service Works.
          </h1>
          <br />
          <br />
          <br />
          <div className="container">
            <Grid container spacing={10}>
              <Grid item md={4} lg={4} xs={12} sm={6}>
                <img src={h1} style={{ width: "100%" }} />
              </Grid>
              <Grid item md={4} lg={4} xs={12} sm={6}>
                <img src={h2} style={{ width: "100%" }} />
              </Grid>
              <Grid item md={4} lg={4} xs={12} sm={6}>
                <img src={h3} style={{ width: "100%" }} />
              </Grid>
            </Grid>
            <br />

            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#fff",
              }}
              className="term_white"
            >
              Your healthcare provided confidently & discreetly by licensed
              physicians
              <br />
            </p>
          </div>

          <br />
        </div>
      </div>
    </div>
  );
};

export default UnderHero;
