import React from "react";

import { Grid } from "@mui/material";

const UnderHero = () => {
  return (
    <div className="">
      <div className=" under_backs">
        <br />

        <div className=" under_backk">
          <br />
          <br />

          <br />

          <h1
            style={{
              textAlign: "center",
              color: "#000",
              fontWeight: "800",
              fontSize: "50px",
            }}
          >
            Body Logic Terms and Conditions
          </h1>

          <br />
          <br />
          <p
            style={{
              textAlign: "center",
              fontWeight: "300",
              fontSize: "16px",
              color: "#000",
            }}
          >
            Last revised May 31st, 2021
          </p>
          <br />
          <div className="term_image_patient">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <br />
          <div className="container">
            <p
              style={{
                textAlign: "start",
                fontWeight: "300",
                color: "#000",
              }}
              className="term_white"
            >
                I agree and authorize BodyLogic to select and secure a medical
              laboratory for diagnostic testing, a prescribing telemedicine
              physician for medical recommendations, and a dispensing and
              compounding pharmacy based in the U.S. for selected
              pharmaceuticals. <br />
              <br />
              I affirm that I have answered and will answer all questions
              truthfully regarding any and all completed medical history forms,
              just as I would in my local physicians office. I specifically hold
              harmless and waive any and all claims or defenses against
              BodyLogic or the treating telemedicine physican selected by
              BodyLogic and will not claim that the consulting physician acted
              unprofessionally or below the standard of care solely because the
              consulting telemedicine physician did not personally physically
              examine me; and am aware that said physician may be located and
              licensed in another state not of my residence. <br />
              <br />
              I affirm I have fully disclosed all information concerning my
              health and medical history truthfully and therefore hold said
              consulting telemidicine physician harmless and waive any and all
              claims and defenses for injuries and/or illnesses I may sustain as
              a result of my failure to comply with method of treatment, dosing
              schedule, or failure to disclose all relevant facts and accurate
              information understanding this could adversely affect my physical
              and/or mental health by causing an adverse response or side
              effect. I understand and acknowledge as a BodyLogic patient I am
              responsible to continually update any changes that take place
              regarding my medical history. <br />
              <br />
              I specifically hold harmless and waive any and all claims or
              defenses against BodyLogic, licensed corporation, its directors,
              officers, shareholders, employees, agents, contractors,
              telemedicine physicians , contracting laboratories and dispensing
              compounding pharmacy for any harm or injury I sustain from any act
              or omission of said treating/ consulting medical doctor or other
              related party. I understand, acknowledge, and am aware that there
              are risks as well as benefits to any medications, as well as
              future consequences and complications involved with standard
              treatment and especially with alternative methods of treatment.{" "}
              <br />
              <br />
              I understand and completely acknowledge the practice of medicine,
              anti-aging medicine, and/or hormone replacement therapy, is not an
              exact science and that therapy for the purpose of elevating my
              hormone blood levels to the highest level of standard reference
              range for my age and sex, or even above such reference range to
              achieve levels of a younger person, is completely individualized.
              I understand and acknowledge that BodyLogic or said
              treating/consulting doctors have made no promises, assurances, or
              guarantees to me regarding my treatment and as this practice of
              elevating my hormone levels as being individualized, may not
              render any benefits, and can even result in unknown adverse
              effects or results presently and/or in the future. <br />
              <br />
              I certify that I have had a recent physical examination by my
              primary physician, and will be responsible for forwarding said
              examinations to BodyLogic and said consulting physicians. I
              understand, acknowledge, and request that BodyLogic and its
              selected licensed telemedicine physicians act only in an adjunct
              consultative capacity, not to replace my primary care physician. I
              further certify that I will contact my primary care physician, or
              other local physicians or emergency personnel for any necessary
              care and intervention for any and all complications, difficulties,
              or adverse reactions regarding my treatment. I certify that any
              medications purchased are for personal use and will not stockpile
              or distribute any medications purchased to others. <br />
              <br />
              I understand and agree to present my photo identification at any
              time blood is drawn pursuant to a BodyLogic testing requirement. I
              understand and agree that all online BodyLogic consultations,
              diagnosis, and treatment will be deemed to have occurred in the
              state where the BodyLogic consulting telemedicine physician is
              licensed and located to practice medicine. <br />
              <br />
              Again, I understand and acknowledge all that I have read, certify
              that I am a competent adult of sound mind, and am 18 years of age
              or older, that I initiated contact with BodyLogic of my own free
              will and under no duress. I certify that I am authorized to use
              the credit card presented to BodyLogic for purchasing medications
              and that said medications will be dispensed directly to me from a
              licensed U.S. based compounding pharmacy. <br />
              <br />
              I understand that any medications and/or supplies purchased are
              NON-REFUNDABLE <br />
              <br />
              I understand that any laboratory testing once purchased is
              NON-REFUNDABLE. <br />
              <br />
              FOR ALL MEDICAL EMERGICES CALL 911 IMMEDIATELY!!!!
            </p>
            <br />

            <br />
          </div>

          <br />
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};

export default UnderHero;
