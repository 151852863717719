import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/medicine.jpeg";
import Divider from "@mui/material/Divider";

import { useCommonDispatch } from "../../store/Common";


function Products() {
  const dispatch = useCommonDispatch()
  const displayQtyInput = (min = 1, max = 10) => {
    dispatch({ type: 'QUANTITY_POPUP_MIN_VALUE', payload: min })
    dispatch({ type: 'QUANTITY_POPUP_MAX_VALUE', payload: max })
    dispatch({ type: 'SHOW_QUANTITY_POPUP', payload: true })
    return false
  }
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backk">
          <br />
          <br />
          <div className="container">
            <h1
              style={{
                textAlign: "start",
                color: "#000",
              }}
              className="head_of_products"
            >
              Sildenafil 110 milligram + Tadalafil 40 milligram Troche
            </h1>
            <br />
            <p
              style={{
                textAlign: "start",
                color: "#000",
                fontWeight: "400",
                fontSize: "15px",
              }}
            >
              Sildenafil and Tadalafil are both here to help men suffering from
              erectile dysfunction regain a normal healthy sex life. ED affects
              millions of men and most don’t know what to do or are to
              embarrassed to ask for help. Sildenafil + Tadalafil is a powerful
              combination and a proven solution to assist men in having a normal
              response to sexual stimulation. Sildenafil + Tadalafil needs to be
              taken 15 - 20 minutes before a man wishes to engage in sexual
              activity. Sildenafil + Tadalafil works by increasing the blood
              flow to the penis and then constricting the arteries to help the
              body maintain an erection. Sildenafil + Tadalafil is only for men
              and is a prescription medication. With the help of Sildenafil +
              Tadalafil millions of men have overcome their sexual difficulties
              and are now enjoying a healthier sex life. Sildenafil + Tadalafil
              not only restores a man’s sex abilities, but also his confidence.
            </p>

            <br />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card"
                >
                  <MDBRow className="g-0">
                    <Grid
                      item
                      md={12}
                      xs={12}
                      lg={12}
                      sm={12}
                      style={{ textAlign: "center" }}
                    >
                      <br />
                      <MDBCardImage
                        src={prod1}
                        alt="..."
                        className="products"
                      />
                      <br />
                    </Grid>
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#000",
                    
                            textAlign: "center",
                          }}
                          className="head_of_products"
                        >
                        
                        </MDBCardTitle>
                        <br />
                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              QTY
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "900",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              Price
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}></Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />10 TROCHES
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={6} sm={6}>
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />
                              $12.00 a Troche
                            </MDBCardText>
                          </Grid>
                          <Grid item md={4} lg={4} xs={12} sm={12}>
                            {" "}
                            <MDBCardText
                              style={{
                                fontWeight: "300",
                                color: "#000",
                                textAlign: "center",
                              }}
                              className="term_white"
                            >
                              <br />{" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",

                                  position: "relative",
                                  top: "-10px",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                                className="term_white"
                              >
                                <>
<a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=998f2d95-77ff-4eea-ba23-1ef2bc9c79a0">

<button className="btn-grado" >Order Now</button>

</a>
                              

                                </>
                               
                              </MDBCardText>
                            </MDBCardText>
                            <Divider variant="middle" className="divider" />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                      
                          <Grid item md={4} lg={4} xs={12} sm={12}>
                            {" "}
                         
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                 20 TROCHES
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $11.00 a TROCHE
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                                   <>
<a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=cc166b4e-46c1-4986-bb01-fbe42871d550">

<button className="btn-grado" >Order Now</button>
</a>


</>
                                </MDBCardText>
                              </MDBCardText>
                              <Divider variant="middle" className="divider" />
                            </Grid>
                          </Grid>
                   

                          <Grid container spacing={2}>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                30 TROCHES
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={6} sm={6}>
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />
                                $10.00 a TROCHE
                              </MDBCardText>
                            </Grid>
                            <Grid item md={4} lg={4} xs={12} sm={12}>
                              {" "}
                              <MDBCardText
                                style={{
                                  fontWeight: "300",
                                  color: "#000",
                                  textAlign: "center",
                                }}
                                className="term_white"
                              >
                                <br />{" "}
                                <MDBCardText
                                  style={{
                                    fontWeight: "300",
                                    color: "#000",

                                    position: "relative",
                                    top: "-10px",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  className="term_white"
                                >
                                  <>
<a href="/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=27442924-9590-4d15-9835-0c4de3fe238a">

<button className="btn-grado">Order Now</button>

</a>


</>
                                </MDBCardText>
                              </MDBCardText>
                            
                            </Grid>
                          </Grid>
                       
                        </Grid>

                        <MDBCardText>
                          <p
                            style={{
                              textAlign: "center",
                              fontWeight: "300",
                              color: "#000",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>

              <br />
              <br />
              <br />
            </Grid>
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
}

export default Products;
