import React, { useState } from "react";
import AnimatedCursor from "react-animated-cursor";
import "./App.css";
import Home from "./screens/Home";
import Aboutus from "./screens/Aboutus";
import TermandCondition from "./screens/TermandCondition";
import Privacy from "./screens/Privacy";
import RefundPolicy from "./screens/RefundPolicies";
import Notice from "./screens/NoticeP";
import Hair from "./screens/Hair";
import Sleep from "./screens/Sleep";
import Vitamin from "./screens/Vitamin";
import EDMalesex from "./screens/MaleSex";
import ScreamFemaleSex from "./screens/FemaleSex";
import Testosterone from "./screens/Testosterone";
import GrowthHoromne from "./screens/GrowthHormone";
import AllHoromne from "./screens/AllHormone";
import AllWeightLossProgram from "./screens/AllWeightLossProgram";
import Reviews from "./screens/Reviews";
import ContactUs from "./screens/Contactus";
import WAYTLess from "./screens/WAYTLess";
import WAYTTain from "./screens/WAYTTain";
import Diet from "./screens/Diet";
import UltraBurn from "./screens/UltraBurn";
import DreamCream from "./screens/DreamCream";
import SidenadilTadaldil from "./screens/SILDENAFIL-TADALAFIL";
import SildenafilPapaverine from "./screens/Sildenafil-Papaverine";
import PEMAXTadalefil from "./screens/PEMaxTadalafil_Apomorphine";
import PTTadalafil from "./screens/PT-Tadalafil";
import Tadaliafil20mgRapidDissolve from "./screens/Tadaliafil20mgRapidDissolve";
import Tadaliafil40mgTroche from "./screens/Tadaliafil40mgTroche";
import GetStarted from "./screens/getstartED";
import SildenafilNoTroche from "./screens/Sildenafil-No-Troche ";
import SILDENAFILNoRapidDissolve from "./screens/SILDENAFILNoRapidDissolve";
import PTComboforWomen from './screens/PT-141ComboforWomen'
import Booking from './screens/Booking';
import ProductPage from "./screens/ProductPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import "./Cursor.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import CommonStore from "./store/Common";
import "./Popup.scss"

function App() {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div className="App">
      <CommonStore>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route index element={<Home />} />
              <Route
                path="Term_and_Condition"
                exact={true}
                element={<TermandCondition />}
              />
              <Route path="Privacy_Policy" exact={true} element={<Privacy />} />
              <Route
                path="Refund_Policy"
                exact={true}
                element={<RefundPolicy />}
              />
              <Route
                path="Notice_of_Privacy_Practices"
                exact={true}
                element={<Notice />}
              />
              <Route path="About_Us" exact={true} element={<Aboutus />} />
              <Route path="Hair_Loss" exact={true} element={<Hair />} />
              <Route path="Sleep" exact={true} element={<Sleep />} />
              <Route path="Vitamin" exact={true} element={<Vitamin />} />
              <Route path="Male" exact={true} element={<EDMalesex />} />
              <Route path="Female" exact={true} element={<ScreamFemaleSex />} />
              <Route
                path="Testosterone"
                exact={true}
                element={<Testosterone />}
              />
              <Route
                path="GrowthHoromne"
                exact={true}
                element={<GrowthHoromne />}
              />
              <Route path="AllHoromne" exact={true} element={<AllHoromne />} />
              <Route
                path="AllWeightLossProgram"
                exact={true}
                element={<AllWeightLossProgram />}
              />
              <Route path="Reviews" exact={true} element={<Reviews />} />
              <Route path="Contact_Us" exact={true} element={<ContactUs />} />
              <Route path="WAYT_less" exact={true} element={<WAYTLess />} />
              <Route path="WAYT_Tain" exact={true} element={<WAYTTain />} />
              <Route path="Diet" exact={true} element={<Diet />} />
              <Route path="UltraBurn" exact={true} element={<UltraBurn />} />
              <Route path="DreamCream" exact={true} element={<DreamCream />} />
              <Route path="PT-141ComboforWomen" exact={true} element={<PTComboforWomen />} />
              <Route
                path="Sidenadil-Tadaldil"
                exact={true}
                element={<SidenadilTadaldil />}
              />
              <Route
                path="Sidenadil-Papaverine"
                exact={true}
                element={<SildenafilPapaverine />}
              />
              <Route
                path="PEMaxTadalafil-Apomorphine"
                exact={true}
                element={<PEMAXTadalefil />}
              />
              <Route
                path="PT141-Tadalafil-Oxytocin"
                exact={true}
                element={<PTTadalafil />}
              />
              <Route
                path="Tadaliafil20mgTroche"
                exact={true}
                element={<Tadaliafil20mgRapidDissolve />}
              />
              <Route
                path="Tadaliafil40mgTroche"
                exact={true}
                element={<Tadaliafil40mgTroche />}
              />
              <Route
                path=""
                exact={true}
                element={<SildenafilNoTroche />}
              />
              <Route
                path="SILDENAFILTroche"
                exact={true}
                element={<SILDENAFILNoRapidDissolve />}
              />

              <Route path="getstartED" exact={true} element={<GetStarted />} />
              <Route path="Book" exact={true} element={<Booking />} />
              <Route path="ProductPage" exact={true} element={<ProductPage />} />


            
            </Routes>
          </Router>
        </ThemeProvider>
      </CommonStore>
    </div>
  );
}

export default App;
