import React from "react";

function HeroSection() {
  return (
    <div className=" background_sleep">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className="hone" style={{ textAlign: "start" }}>
          Sleep <br />
          Trazodone
          <span style={{ color: "#3cb1a0", fontSize: "32px" }}>
            <br />
            For the treatment of Insomnia
          </span>
        </h1>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white"
        >
          Can't rest?
          <br />
          It's time for a good night's sleep
        </p>
        <br />

        <button className="btn-grad">Get Started</button>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
