import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/prod1.png";
import prod2 from "./image/prod2.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
      

        <div className=" under_backk">
          <br />
          <br />
          <div className="container">
            <h1
              style={{
                textAlign: "start",
                color: "#fff",
                fontWeight: "700",
                fontSize: "40px",
              }}
            >
              WHAT WE OFFER
            </h1>
            <br />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card_male"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="12">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#fff",
                            fontWeight: "800",
                            fontSize: "34px",
                          }}
                        >
                       Best Treatments for Female
                        </MDBCardTitle>

                        <br />
                        <MDBCardText
                          style={{
                            textAlign: "start",
                            fontWeight: "300",
                            color: "#fff",
                          }}
                          className="term_white"
                        >
                         BodyLogic has made available a selection of very effective treatments for female's sexual dysfunction.  Learn more by exploring the treatment options here below.
                        </MDBCardText>
                        <MDBCardText>
                          <br />
                          <div className="center">
                            {" "}
                            <a href="./DreamCream">
                              {" "}
                              <button className="btn-grad" style={{textDecoration:"none"}}>Dream Cream</button>
                            </a> 
                            <a href="./PT-141ComboforWomen">
                              {" "}
                              <button className="btn-grad" style={{textDecoration:"none"}}>PT-141 Combo</button>
                            </a>
                          
                          </div>
                          <br />

                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If Medically
                            Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>

              <br />
              <br />
              <br />
            </Grid>
            <br />
            <br />
          </div>
        </div>
      
      </div>
    </>
  );
}

export default Products;
