import React, { useContext, createContext, useReducer } from 'react'

const CommonStateContext = createContext("bodylogic");
const CommonDispatchContext = createContext("bodylogic");

const initialState = {
    showQuantityPopup: false,
    qtyPopupMinValue: 1,
    qtyPopupMaxValue: 10,
}

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_QUANTITY_POPUP':
            return { ...state, showQuantityPopup: action.payload }
        
        case 'QUANTITY_POPUP_MIN_VALUE':
            return { ...state, qtyPopupMinValue: action.payload }

        case 'QUANTITY_POPUP_MAX_VALUE':
            return { ...state, qtyPopupMaxValue: action.payload }
        default: return state
    }
}

const CommonStore = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <CommonStateContext.Provider value={state}>
            <CommonDispatchContext.Provider value={dispatch}>
                {children}
            </CommonDispatchContext.Provider>
        </CommonStateContext.Provider>
    )
}

export default CommonStore

export function useCommonDispatch() {
    const context = useContext(CommonDispatchContext);
    return context;
}

export function useCommonState() {
    const context = useContext(CommonStateContext);
    return context;
}
