import React, { Suspense ,  StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import logo from "./cclogo.png";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

//  import 'swiper/css/bundle';

import reportWebVitals from "./reportWebVitals";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const root = ReactDOM.createRoot(document.getElementById("root"));

const loadingMarkup = (
  <div
    className="py-30 text-center "
    style={{
      marginTop: "350px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    }}
  >
    <h1 data-aos="fade-up">
      <img src={logo} />
    </h1>
  </div>
);

root.render(
  <StrictMode>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
