import React from "react";

function HeroSection() {
  return (
    <div className="background_comb_med_combone">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className="hone" style={{  color:"#fff" }}>
          COMBINATION
          <br />
          <span style={{ color: "#3cb1a0" , fontSize:"25px"}}>
            <br />
            Sildenafil 110 milligram + <br/>Tadalafil 40 milligram Troche
          </span>
        </h1>
        <br />
        <h1 className="honeoi" style={{ color: "#fff" }}>
        Results within 15 - 20 minutes 
        
        </h1>
        <h1 className="honeoiw" style={{ color: "#fff" }}>
    
         with Fast Acting Sublingual Dissolving Troches
        </h1>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
