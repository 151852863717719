import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/prod2.png";
import prod2 from "./image/prod2.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backs">
          <br />

          <div className=" under_backk">
            <br />
            <br />
            <div className="container">
              <h1
                style={{
                  textAlign: "start",
                  color: "#fff",
                  fontWeight: "700",
                  fontSize: "40px",
                }}
              >
                WHAT WE OFFER
              </h1>
              <br />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12} lg={12} sm={12}>
                  <MDBCard
                    style={{ width: "100%", height: "100%" }}
                    className="background_product_card"
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="5">
                        <MDBCardImage
                          src={prod1}
                          alt="..."
                          style={{ width: "100%", height: "100%" }}
                        />
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBCardBody>
                          <br />
                          <br />
                          <MDBCardTitle
                            style={{
                              color: "#fff",
                              fontWeight: "800",
                              fontSize: "34px",
                            }}
                          >
                            What is Trazodone?
                          </MDBCardTitle>

                          <br />
                          <MDBCardText
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white"
                          >
                            Originally approved as an antidepressant medication,
                            studies have found that trazodone in low doses can
                            help increase the availability of serotonin.
                            Serotonin is used by our bodies to stabilize our
                            mood, feelings of well-being and promotes sleep.
                            Goodbye to sleepless nights, hello good mood!
                          </MDBCardText>
                          <MDBCardText>
                            <br />
                            <small className="text-muted">
                              <button className="btn-grads">Get Started</button>
                            </small>
                            <br />

                            <p
                              style={{
                                textAlign: "start",
                                fontWeight: "300",
                                color: "#fff",
                              }}
                              className="term_white_hair_under"
                            >
                              Prescribed via Digital Physician Visit If
                              Medically Appropriate
                            </p>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </Grid>

                <Grid item md={12} xs={12} lg={12} sm={12}>
                  <MDBCard
                    style={{ width: "100%", height: "100%" }}
                    className="background_product_card"
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="5">
                        <MDBCardImage
                          src={prod2}
                          alt="..."
                          style={{ width: "100%", height: "100%" }}
                        />
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBCardBody>
                          <br />
                          <br />
                          <MDBCardTitle
                            style={{
                              color: "#fff",
                              fontWeight: "800",
                              fontSize: "34px",
                            }}
                          >
                            Benefits of Trazodone can include...
                          </MDBCardTitle>
                          <br />
                          <br />
                          <MDBCardText
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white"
                          >
                            <DoneOutlineIcon /> Trazodone may be less expensive
                            than some newer insomnia medicines because it is
                            available generically.
                            <br />
                            <DoneOutlineIcon /> In low doses trazadone is
                            generally not felt to be addictive.
                            <br />
                            <DoneOutlineIcon /> Maintain healthy hair
                            <br />
                            <DoneOutlineIcon /> Trazodone is a non-controlled
                            substance unlike some other sleep aids such as
                            benzodiazepines.
                            <br />
                            <DoneOutlineIcon /> Studies have shown that
                            trazodone may be useful as a sleep aid at low doses
                            of 25-50 mg.
                          </MDBCardText>
                          <MDBCardText>
                            <br />
                            <br />
                            <small className="text-muted">
                              <button className="btn-grads">Get Started</button>
                            </small>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </Grid>
              </Grid>

              <br />
              <br />
            </div>
            <br />
            <br />
            <div className="magic1_image_patient">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>

            <div className=" back_refund">
              <div className="container">
                <br />
                <br />

                <p
                  style={{
                    textAlign: "start",
                    fontWeight: "300",
                    color: "#fff",
                  }}
                  className="term_white"
                >
                  These statements have not been evaluated by the Food and Drug
                  Administration. These products are not intended to diagnose,
                  treat, cure, or prevent any disease.
                  <br />
                  <br />
                </p>

                <br />
                <br />
              </div>

              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
