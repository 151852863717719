import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Grid } from "@mui/material";
import prod1 from "./image/prod2.png";
import prod2 from "./image/prod2.png";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
function Products() {
  return (
    <>
      {" "}
      <div className="">
        <div className=" under_backs">
          <br />

          <div className=" under_backk">
            <br />
            <br />
            <div className="container">
              <h1
                style={{
                  textAlign: "start",
                  color: "#fff",
                  fontWeight: "700",
                  fontSize: "40px",
                }}
              >
                WHAT WE OFFER
              </h1>
              <br />
              <Grid container spacing={2}>
                <Grid item md={12} xs={12} lg={12} sm={12}>
                  <MDBCard
                    style={{ width: "100%", height: "100%" }}
                    className="background_product_card"
                  >
                    <MDBRow className="g-0">
                      <MDBCol md="5">
                        <MDBCardImage
                          src={prod1}
                          alt="..."
                          style={{ width: "100%", height: "100%" }}
                        />
                      </MDBCol>
                      <MDBCol md="7">
                        <MDBCardBody>
                          <br />
                          <br />
                          <MDBCardTitle
                            style={{
                              color: "#fff",
                              fontWeight: "800",
                              fontSize: "34px",
                            }}
                          >
                            What is Trazodone?
                          </MDBCardTitle>

                          <br />
                          <MDBCardText
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white"
                          >
                            Originally approved as an antidepressant medication,
                            studies have found that trazodone in low doses can
                            help increase the availability of serotonin.
                            Serotonin is used by our bodies to stabilize our
                            mood, feelings of well-being and promotes sleep.
                            Goodbye to sleepless nights, hello good mood!
                          </MDBCardText>
                          <MDBCardText>
                            <br />
                            <small className="text-muted">
                              <button className="btn-grads">Get Started</button>
                            </small>
                            <br />

                            <p
                              style={{
                                textAlign: "start",
                                fontWeight: "300",
                                color: "#fff",
                              }}
                              className="term_white_hair_under"
                            >
                              Prescribed via Digital Physician Visit If
                              Medically Appropriate
                            </p>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </Grid>
                <Grid item md={12} xs={12} lg={12} sm={12}>
                <MDBCard
                  style={{ width: "100%", height: "100%" }}
                  className="background_product_card"
                >
                  <MDBRow className="g-0">
                    <MDBCol md="5">
                      <MDBCardImage
                        src={prod1}
                        alt="..."
                        style={{ width: "100%", height: "100%" }}
                      />
                    </MDBCol>
                    <MDBCol md="7">
                      <MDBCardBody>
                        <br />
                        <br />
                        <MDBCardTitle
                          style={{
                            color: "#fff",
                            fontWeight: "800",
                            fontSize: "34px",
                          }}
                        >
                          What is Trazodone?
                        </MDBCardTitle>

                        <br />
                        <MDBCardText
                          style={{
                            textAlign: "start",
                            fontWeight: "300",
                            color: "#fff",
                          }}
                          className="term_white"
                        >
                          Originally approved as an antidepressant medication,
                          studies have found that trazodone in low doses can
                          help increase the availability of serotonin.
                          Serotonin is used by our bodies to stabilize our
                          mood, feelings of well-being and promotes sleep.
                          Goodbye to sleepless nights, hello good mood!
                        </MDBCardText>
                        <MDBCardText>
                          <br />
                          <small className="text-muted">
                            <button className="btn-grads">Get Started</button>
                          </small>
                          <br />

                          <p
                            style={{
                              textAlign: "start",
                              fontWeight: "300",
                              color: "#fff",
                            }}
                            className="term_white_hair_under"
                          >
                            Prescribed via Digital Physician Visit If
                            Medically Appropriate
                          </p>
                        </MDBCardText>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </Grid>
         
              </Grid>

              <br />
              <br />
            </div>
            <br />
            <br />
          

        
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
