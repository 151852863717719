import React from "react";

function HeroSection() {
  return (
    <div className="background_comb_med_comfou">

<div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1 className="hone" style={{ textAlign: "start", color: "#fff" }}>
        COMBINATION
          <span style={{ color: "#3cb1a0" , fontSize:"25px"}}>
            <br />
            PT-141 2 mg +<br/>Tadalafil 20 mg +<br/> Oxytocin 100 iu
          </span>
        </h1>
        <br />
        <h1 className="honeoi" style={{ color: "#fff" }}>
        Results within 15 - 20 minutes 
        
        </h1>
        <h1 className="honeoiw" style={{ color: "#fff" }}>
    
         with Fast Acting Sublingual Dissolving Troches
        </h1>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
     
    </div>
  );
}

export default HeroSection;
