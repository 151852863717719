import React from "react";
import { Grid } from "@mui/material";
function HeroSection() {
  return (
    <div className=" background_review">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={2}>
        <Grid item md={6} lg={6} xs={12} sm={12}></Grid>

        <Grid item md={6} lg={6} xs={12} sm={12}>

        <h1 className="honere" style={{ textAlign: "start" }}>
          Body Logic <br />
          Reviews
          <span style={{ color: "#3cb1a0", fontSize: "22px" }}>
            <br />
            Making Happy Clients  <br />Is Our Purpose
          </span>
        </h1>
        <br />  <br />   <br />
        <p
          style={{
            textAlign: "center",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white"
        >
          What Our Patients are <br /> Saying About Body Logic
        </p>
        <br />

         <br />


        </Grid>
        </Grid>
        
      
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default HeroSection;
