
const AllProducts = [
    { amount: 1, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=67a264d9-2dfa-4139-a7e6-cf670d4f3022' },
    { amount: 2, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=6269f1a9-be29-45d0-9cc8-2e770e57e491' },
    { amount: 3, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=be79c8ee-3496-4688-823a-d3bad3315fcc' },
    { amount: 4, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=c0e9dccf-3154-48b2-8252-3eaa8a5f8145' },
    { amount: 5, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=137faad3-d34c-4fd4-8b3e-b605687b47a4' },
    { amount: 6, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=18a6b78e-0daa-4236-8ce7-a4b814b6b969' },
    { amount: 7, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=254c96e4-eeb4-4144-921e-52248e7de2e1' },
    { amount: 8, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=d2390f95-26e2-4286-b056-dcb929255453' },
    { amount: 9, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=14804623-8daa-48c5-a4e7-a8e9eda0ac3b' },
    { amount: 10, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=1552da63-9f16-441b-8ca0-a74b6cdcd89b' },
    { amount: 11, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=a260baae-9962-4575-bda7-64b4f8fa8096' },
    { amount: 12, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=16fe4de5-0993-49c0-9942-38be18dd003c' },
    { amount: 13, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=12fea9df-4a05-4755-bc4c-5e36da3abafd' },
    { amount: 14, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=47f10c1b-9d8d-4fa4-b08b-9f040cf81fdc' },
    { amount: 15, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=4865e68c-591a-47d8-a081-720f9d609e1f' },
    { amount: 16, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=34b8781a-52d9-42c4-8d6f-c5876c5caeee' },
    { amount: 17, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=7d0db471-9dee-4fe7-b436-87f999a37bf2' },
    { amount: 18, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=08944734-37e4-4b4a-9c49-e8ed7db6bb07' },
    { amount: 19, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=f995fa10-2040-42bc-81d6-26347fa465c6' },
    { amount: 20, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=e5618ef9-bb0c-45ae-91ff-43da8e3898dc' },
    { amount: 21, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=46daac3e-6ff2-47f9-b5c8-215ab4c173af' },
    { amount: 22, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=c247f315-3afb-428c-b439-467b79bf6389' },
    { amount: 23, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=c508e276-f48a-4546-9f3d-e0aef78dca84' },
    { amount: 24, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=84127354-5eba-4d38-8709-26d4b5bc93a2' },
    { amount: 25, link: '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=9129f409-5a7c-4943-949d-cdccee31c87b' },
    { amount: 26, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=99bfb3d8-d7ae-450d-ad3c-b31dbe1f1739' },
    { amount: 27, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=b174a6b7-ee35-4987-ad44-47c18b5102c0' },
    { amount: 28, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=0d6460d2-d11b-4ee2-91d3-eb3146306731' },
    { amount: 29, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=23bfef10-93ef-4d6a-8a55-4868763c125a' },
    { amount: 30, link:  '/book?invite_booking_with_mss=53f9f9a0-228a-4c84-ae06-caa5edd63f9a&marketing_group_id=40c289ee-0b6d-4f49-9c2f-a1d3b0275301&medical_speciality=c6cfec70-55ef-4ae7-b020-53705e65f82e&product_ids=de412df1-68be-4596-8481-62c159de8f6d' },
]

export default AllProducts