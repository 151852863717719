import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Navbar from "../Component/Navbar";
import DesktopNavbar from "../Component/DesktopNavbar";
import HeroSection from "../Component/HOME/HeroSection";
import MobileHeroSection from "../Component/HOME/MobileHeroSection";
import Testosterone from "../Component/HOME/Testosterone";
import MobileTestosterone from "../Component/HOME/MobileTestosterone";
import SexualHealth from "../Component/HOME/Sexual";
import SexualHealthMobile from "../Component/HOME/SexualMobile";
import Hair from "../Component/HOME/Hair";
import TestnomailsPatient from "../Component/HOME/TestnomialsPatient";
import MobileTestnomailsPatient from "../Component/HOME/MobileTestnomialsPatient";
import Telemedicine from "../Component/HOME/Telemedicine";
import MobileTelemedicine from "../Component/HOME/MobileTelemedicine";
import Footer from "../Component/HOME/Footer";




function Garden() {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}
      {/* <Slider /> */}
      {!smallScreen && (
        <div className="back" style={{ background: "#fff" }}>
          <DesktopNavbar />
          <HeroSection />
          <SexualHealth />
          <Testosterone />
      
          <TestnomailsPatient />
          <Telemedicine />
          <Footer />

        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div className="back" style={{ background: "#fff" }}>
          <Navbar />      <br/><br/>
          <MobileHeroSection />
          <SexualHealthMobile />
          <MobileTestosterone />
      
          <MobileTestnomailsPatient />
          <MobileTelemedicine />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
  );
}

export default Garden;
