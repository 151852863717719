import React from "react";
import { Grid } from "@mui/material";
function HeroSection() {
  return (
    <div className="background_productPage">

<div className="container ">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      
        <br />
        <br />
        <br />
        <br />    <br />
        <br />
        <br />   <br />
        <br />
        <br />
        <br />
      
        <h1
  style={{
    textAlign: "center",
    color: "#fff",
   
  }}
  className="head_of_productsP"
> 
<span style={{fontWeight:"900"}}>GET   <br />STARTED</span>




</h1>

        <br />
        <br />
        <br />
      </div>
   
    </div>
  );
}

export default HeroSection;
