import React from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Navbar from "../Component/Navbar";
import DesktopNavbar from "../Component/DesktopNavbar";
import HeroSection from "../Component/SILDENAFIL-PAPA/HeroSILDENADIL-PAPA";
import Product from "../Component/SILDENAFIL-PAPA/PRODUCTSILDENAFIL-PAPAV";
import Footer from "../Component/HOME/Footer";
import AllProducts from "../Products/SILDENAFIL-PAPAV";
import QuantityPopup from "../Component/Popup/Quantity";
const PricingClasses = [
  { min: 1, max: 10, basePrice: 12 },
  { min: 11, max: 20, basePrice: 11 },
  { min: 21, max: 30, basePrice: 10 },
];

function Garden() {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="back" style={{ background: "#000" }}>
          <DesktopNavbar />
          <HeroSection />
          <Product />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div className="back" style={{ background: "#000" }}>
          <Navbar />      <br/><br/>
          <HeroSection />
          <Product />
          <Footer />
        </div>
      )}
       <QuantityPopup products={AllProducts} pricingClasses={PricingClasses} />
    </div>
  );
}

export default Garden;
