import React from "react";
import "./Form.css";
function ContactUsSection() {
  return (
    <div>
      <br />
      <br />
      <div class="wrapper">
        <br />
        <form class="form">
          <br />
          <div class="pageTitle title">Email Us</div>
          <div class="secondaryTitle title"></div>
          <input type="text" class="name formEntry" placeholder="Name" />

          <input type="text" class="name formEntry" placeholder="Email" />
          <input
            type="text"
            class="name formEntry"
            placeholder="Phone Number"
          />

          <textarea class="message formEntry" placeholder="Message"></textarea>
          <input type="checkbox" class="termsConditions" value="Term" />
          <label style={{ color: "grey" }} for="terms">
            {" "}
            I Accept the <span style={{ color: "#0e3721" }}>
              Terms of Use
            </span>{" "}
            & <span style={{ color: "#0e3721" }}>Privacy Policy</span>.
          </label>
          <br />
          <button class="submit formEntry" onclick="thanks()">
            Submit
          </button>
        </form>
        <br />
        <br />
        <form class="form">
          <div
            class="pageTitle title"
            style={{
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Contact Us
          </div>
          <a
            href="tel:+1-800-480-4405"
            id="style-2"
            className="  text_head"
            style={{
              color: "#3cb1a0",
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              fontWeight:"900"
            }}
          >
            +1-800-480-4405
          </a>
        </form>
        <br />
        <br />

      
      </div>
    </div>
  );
}

export default ContactUsSection;
