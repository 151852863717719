import React from "react";

function HeroSection() {
  return (
    <div className="background_femaless">
      <div className="container">
        <br />
        <br />
        <br /> <br />
        <br />
        <br /> <br />
        <br />
        <br />    <br />
        <br />
        <br />
        <br /><br /> 
        <br />
        <br />
        <br />
        <h1 className="honemf" style={{ textAlign: "start" }}>
        Treatments for Female's  <br />
 Sexual Dysfunction
          <span style={{ color: "#3cb1a0", fontSize: "22px" }}>
            <br />
           
          </span>
        </h1>
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white"
        >
        
        </p>
        <br />
        <br /> <br />
 
        <br />
        <p
          style={{
            textAlign: "start",
            fontWeight: "300",
            color: "#fff",
          }}
          className="term_white_hair_under"
        >
          Prescribed via Digital Physician Visit If Medically Appropriate
        </p>
        <br />
    
      </div>
    </div>
  );
}

export default HeroSection;
