import React from "react";
import "./UnderCardsWayTLess.scss";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Grid } from "@mui/material";

function UnderCardsWayTLess() {
  return (
    <div>
  

      <div className=" back_refund">
        <div className="container">
          <br />
          <br />
          <h1
            style={{
              textAlign: "start",
              fontWeight: "800",
              color: "#fff",
            }}
          >
            Loading days
          </h1>
          <br />
          <p
            style={{
              textAlign: "start",
              fontWeight: "300",
              color: "#fff",
            }}
            className="term_white"
          >
            You should eat between 1,500-2,000 calories per day for the first
            two days. The best types of food to consume on loading days are
            high-fat foods while keeping carbs to a minimum.
            <br />
            <br />
            Examples: Steak, beef, pork, salmon, cheese, olives, olive oil,
            avocado, nuts, nut butters, butter, dairy, and seeds.
            <br />
            <br />
          </p>
          <br />
          <br />
        </div>

        <br />
      </div>


      <div className="container">
      <Grid container spacing={2}>
        <Grid item md={4} lg={4} xs={12} sm={12}>
          {" "}
          <li class="cards_item" id="item_salad">
            <div class="cards">
              <div class="card_content">
                <div class="card_text">
                  <h2 class="">Macro targets</h2>
                  <p>
                    Carbs: 30 - 40 grams
                    <br />
                    Protein: 100 grams
                    <br />
                    Fat: 9 grams
                    <br />
                    Total Calories: Up to 800 calories.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </Grid>

        <Grid item md={4} lg={4} xs={12} sm={12}>
          {" "}
          <li class="cards_item" id="item_salad">
            <div class="cards">
              <div class="card_content">
                <div class="card_text">
                  <h2 class="">Net carbs</h2>
                  <p>
                    Total Carbs - Fiber = Net Carbs
                    <br />
                    Protein: 100 grams
                    <br />
                    Fat: 9 grams
                    <br />
                    Total Calories: Up to 800 calories.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </Grid>

        <Grid item md={4} lg={4} xs={12} sm={12}>
          {" "}
          <li class="cards_item" id="item_salad">
            <div class="cards">
              <div class="card_content">
                <div class="card_text">
                  <h2 class="">Proteins</h2>
                  <p>
                    Total Carbs - Fiber = Net Carbs
                    <br />
                    Protein: 100 grams
                    <br />
                    Fat: 9 grams
                    <br />
                    Total Calories: Up to 800 calories.
                  </p>
                </div>
              </div>
            </div>
          </li>
        </Grid>
      </Grid>
    </div>
    </div>
  );
}

export default UnderCardsWayTLess;
